.ql-editor {
  background-color: #262525;
  color: white !important;
  min-height: 40vh;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f; /* <== change this color */
  color: #f8f8f2;
  overflow: visible;
}
.ql-snow .ql-container {
  border: none;
  background: #1c1c1c;
}
.editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #1c1c1c;
  border: none;
  color: #ffffff;
}
.ql-picker {
  color: #ffffff !important;
}
.ql-picker-item {
  color: #000000;
}
.ql-picker-item:hover {
  color: #38cd84 !important;
}
.quill {
  border: none;
}
.ql-toolbar.ql-snow {
  background: #1c1c1c;
  border-left: none;
  border-right: none;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: #38cd84 !important;
}
.ql-snow.ql-toolbar button.ql-active {
  color: #38cd84 !important;
}
.ql-snow .ql-stroke {
  stroke: #ffffff;
}
.ql-snow .ql-fill {
  fill: #ffffff;
}
.ql-bold .ql-active {
  stroke: #38cd84 !important;
}
.ql-snow.ql-toolbar button:hover {
  stroke: #38cd84 !important;
}
