.select {
  position: relative;
  text-align: left;
  cursor: pointer;
  background-color: "red";
}
.select__label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 100;
  height: 40px;
  position: absolute;
  opacity: 0.8;
  color: #303233;
}
.select__label p {
  transition: all 0.3s;
  margin-top: 30px;
  margin-left: 15px;
}
.select__value {
  font-weight: 100;
  border-bottom: solid 2px #03a9f4;
  margin-top: 10px;
  padding: 30px 15px 15px;
  opacity: 0.8;
  min-height: 20px;
}
.select__value p {
  margin: 0px;
  min-height: 20px;
}
.select__options {
  background: #f4f5f7;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  transition: opacity 0.2s, padding 0.2s, max-height 0.2s;
  overflow: scroll;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.select__options .select__option {
  padding: 13px;
  transition: all 0.2s;
}
.select__options .select__option:hover,
.select__options .select__option-active {
  background: rgba(0, 0, 0, 0.1);
}
.select-active .select__trigger {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9;
}
.select-active .select__options {
  max-height: 180px;
  opacity: 1;
}
.select-active .select__label,
.select.selected .select__label {
  font-size: 13px;
  font-weight: 900;
  opacity: 1;
}
.select-active .select__label p,
.select.selected .select__label p {
  margin-top: 0px;
  margin-left: 0px;
}
.select-deactive .select__value {
  animation: fadeIn 0.3s;
}
.select-deactive .select__options {
  background: #f4f5f7;
  max-height: 0px;
  opacity: 0;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
