.App {
  font-family: "Raleway-medium";
  text-align: center;
}

.multi-select {
  margin-left: 0px;
  /* border: 1px solid #242424; */
}

.dropdown-heading {
  background: #5a5858 !important;
  cursor: pointer !important;
  color: white !important;
  border: 1px solid #5a5858 !important;
  border-radius: 4px !important;
  padding: 9px;
  font-family: "Raleway" !important;
  margin-left: 0px;
}
.dropdown-heading-value {
  color: white !important;
}
.dropdown-heading-value:hover {
  color: #4e4e4e;
}
.item-renderer span {
  display: flex;
}
.dropdown-heading .dropdown-heading- span {
  color: white !important;
  font-family: "Raleway" !important;
}
.dropdown-heading:hover,
.dropdown-heading:hover .dropdown-heading- span {
  background: white !important;
  color: #4e4e4e !important;
}

/* .dropdown-heading .dropdown-heading-:hover{
  color: #4e4e4e !important;
}

.dropdown-heading .dropdown-heading- span {
  color: white !important;
}
.dropdown-heading .dropdown-heading- span:hover{
  color: #4e4e4e !important;
} */

.select-item .item-renderer span {
  font-family: "Raleway" !important;
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: #4e4e4e; */
  /* color: #4e4e4e; */
}
.select-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: #4e4e4e; */
  /* color: #4e4e4e; */
}
.select-item {
  /* background: #4e4e4e; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: none;
}
.select-list {
  /* background: #4e4e4e; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: none;
  padding: 0px;
  background-clip: none;
  color: #4e4e4e;
  box-sizing: none;
}

.item-renderer {
  box-sizing: none;
  /* background: #4e4e4e;
  color: #fff; */
  background-clip: none;
}

.dotp:before {
    content: "•";
    color: #38CD84;
    text-shadow: #38CD84 0 0 10px;
    margin:0 10px;
}




@media only screen and (max-width: 600px) {
  .job-outer{
    padding: 0px 0px
  }

}

@media only screen and (min-width: 600px) {
  .nav-style{
    margin-bottom: -9px
  }

}

@media only screen and (max-width: 600px) {
  .nav-style{
    padding-bottom: 24px;
    margin-bottom: 0px;
  }

}

@media only screen and (min-width: 600px) {
  .job-outer{
    padding: 0px 80px
  }

}
/* @media only screen and (max-width: 412px) {
  .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #4e4e4e;
  }
  .select-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #4e4e4e;
  }
  .select-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: none;
  }
  .select-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: none;
    padding: 0px;
    background-clip: none;
    color: #4e4e4e;
    box-sizing: none;
  }
  .select-list li {
    background-color: #4e4e4e;
    background: #4e4e4e;
    color: #4e4e4e;
    box-sizing: none;
    background-clip: none;
  }
  .item-renderer {
    color: #4e4e4e;
    box-sizing: none;
    background-color: #4e4e4e;
    background: #4e4e4e;
    color: #fff;
    background-clip: none;
  }
} */


.logo_brand_img{
  width: 200px;
}

.logo_brand{
  height: 80px;
}

.home-see-more{
  display: flex;
  justify-content: flex-end;
  padding: 15px 100px;;
}

.home-see-more-a{
  color: #fff;
}
