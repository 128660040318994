body::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}
body {
  font-family: 'Raleway-medium';
  overflow: visible;
  height: 100vh;
  background-color: #1f1e1e;
}

.App {
  background-color: #1f1e1e;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.cls-copy {
  margin-bottom: 8px;
}

.react-player {
}

.react-player > video {
  max-height: 60vh;
}
.input-counter {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  position: relative;
}
.input-counter span {
  top: 0;
  width: 30px;
  height: 100%;
  font-size: 15px;
  cursor: pointer;
  line-height: 50px;
  position: absolute;
  color: var(--paragraphColor);
  background-color: #38cd84;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.input-counter span.minus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  border-right: 1px solid #38cd84;
}
.input-counter span.plus-btn {
  right: 0;
  border-left: 1px solid #38cd84;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-counter span:hover {
  color: var(--primaryColor);
}
.input-counter input {
  outline: 0;
  width: 100%;
  height: 27px;
  display: block;
  text-align: center;
  color: var(--blackColor);
  border: 1px solid #38cd84;
  background-color: var(--whiteColor);
  font-size: 17px;
  font-weight: 600;
}
.input-counter input::placeholder {
  color: var(--blackColor);
}

/* 
.select {
  position: relative;
  text-align: left;
  cursor: pointer; */

/* &__label {
    font-size: 16px;
    line-height: 16px;
    font-weight: 100;
    height: 40px;
    position: absolute;
    opacity: 0.8;
    color: #303233;

    p {
      transition: all 0.3s;
      margin-top: 30px;
      margin-left: 15px;
    }
  }

  &__value {
    font-weight: 100;
    border-bottom: solid 2px #03a9f4;
    margin-top: 10px;
    padding: 30px 15px 15px;
    opacity: 0.8;
    min-height: 20px;

    p {
      margin: 0px;
      min-height: 20px;
    }
  }

  &__options {
    background: #f4f5f7;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    transition: opacity 0.2s, padding 0.2s, max-height 0.2s;
    overflow: scroll;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;

    .select__option {
      padding: 13px;
      transition: all 0.2s;

      &:hover,
      &-active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-active {
    .select__trigger {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 9;
    }

    .select__options {
      max-height: 180px;
      opacity: 1;
    }
  }

  &-active,
  &.selected {
    .select__label {
      font-size: 13px;
      font-weight: 900;
      opacity: 1;

      p {
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }

  &-deactive {
    .select__value {
      animation: fadeIn 0.3s;
    }

    .select__options {
      background: #f4f5f7;
      max-height: 0px;
      opacity: 0;
    }
  }
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

main {
  overflow-y: auto;
  scrollbar-width: none;
  width: 100%;
}
main::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}
aside {
  overflow-y: auto;
  scrollbar-width: none;
}
aside::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}

ul {
  list-style: none;
}
li {
  color: #fff;
}
aside {
  /* border-right:  1px solid #ededed; */
  /* background: #36c; */
  flex: 1 0 25%;
  /* padding: 1rem; */
}
.wrapper_home {
  display: flex;
  height: calc(100vh - 68px);
  width: 1280px;
  margin: auto;
}
@media (max-width: 500px) {
  .wrapper_home {
    flex-direction: column-reverse;
  }
  .wrapper_home > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.job-apply {
  overflow-y: scroll;
  height: 70vh;
}
.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
}

@media only screen and (max-width: 500px) {
  .job-apply {
    overflow-y: scroll;
    height: 70vh;
  }
}

.wrapper_inner {
  width: 100%;
  margin: 0px auto;
}

.infinite-scroll-component {
  scrollbar-width: none;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 0px;
}

.trianglecontainer {
  position: relative;
  width: 100%;

  border-radius: 12px;
  overflow: hidden;
}

.trianglecontainer:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 35px 35px 0;
  border-color: transparent #ffffff transparent transparent;
  right: 18px;
  top: 0;
  position: absolute;
}
