
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');


@-o-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
html {
  overflow-y: auto;
  font-family: 'Raleway SemiBold', ' Raleway', 'Open Sans';
}
.container {
  padding: 0;
  overflow-x: hidden;
}
*
/* html {
  font-family: "Raleway SemiBold", " Raleway", "Open Sans";
} */
.topicon {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 0;
  float: left;
  opacity: 0.9;
  border-radius: 10px;
  z-index: 99;
}
.topicon img {
  width: 50%;
}
.logo-image-section {
  padding-left: 0 !important;
}
.become-a-member-btn {
  font-weight: 600;
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  background-color: #61c382;
  padding: 9px 15px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.new-landing-page {
  margin-top: 70px;
  background-color: #262525;
  color: #fff;
}
.numbers {
  font-family: 'Open Sans';
}
/* global class reusable start */
.color-green {
  color: #38cd84;
  padding-top: 60px;
  font-family: 'Raleway';
  font-weight: 600;
}

.jobsheading {
  color: #38cd84;
  padding-top: 120px;
  font-family: 'Raleway';
  font-weight: 600;
}
.eventheading {
  color: #38cd84;
  padding-top: 90px;
  padding-bottom: 20px;
  font-family: 'Raleway';
  font-weight: 600;
}
/* global class reusable end */

/* ==== row === */
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* ===================== banner section start================ */
.nl-banner {
  background-image: url('../../resources/new-img/banner.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding: 80px 130px 0px 130px;
  background-size: 100% 100%;
}
.nl-banner-left {
  margin-top: 100px;
}

.nl-banner-left-title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
}

.nl-banner-img-left {
  width: 490px;
  height: 480px;
  margin-top: 20px;
  margin-left: 0;
  left: 60px;
}
.nl-banner-img-right {
  width: 230px;
  height: 430px;
  position: absolute;
  top: -45px;
  margin-top: 90px;
  z-index: 1;
}
.nl-banner-gplay {
  margin-top: 30px;
}

/* ==================== banner section end ===========*/
/* ====================About US start=============== */
.nl-about-us {
  background-image: url('../../resources/new-img/BG1.png');
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 0px 80px;
  background-position: center;
}
.nl-about-us1 {
  display: none;
}

.nl-about-us h1 {
  margin-bottom: 35px;
}
.nl-about-us-intro {
  color: #fff;
  font-size: 16px;
  margin-bottom: 40px;
  padding: 0px 50px;
  -webkit-columns: 20px 2;
  column-gap: 60px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
}

.nl-about-us-count-sec {
  text-align: center;
  display: flex;
  margin-top: 30px;
  padding: 0px 150px;
  flex-wrap: wrap;
}
.count-col1,
.count-col2,
.count-col3 {
  flex: 1;
}
.nl-about-us-count-sec p {
  font-family: 'Open Sans';
  font-size: 50px;
}
.nl-about-us-count-sec h3 {
  color: #fff;
}
.nl-about-us-vision {
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.nl-about-us-vision p {
  color: #fff;
  font-size: 16px;
  padding: 0px 120px;
}
.nl-about-us-vision h3 {
  color: #fff;
}
/* ====================About US End=======================*/
/* ================why linkcxo start========== */
.nl-why-linkcxo {
  background-image: url('../../resources/new-img/Video\ -\ BG.png');
  background-repeat: no-repeat;
  /* margin-top: 80px; */
  width: 100%;
  height: auto;
  /* margin-bottom: 20px; */
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nl-why-linkcxo h1 {
  font-family: 'Raleway SemiBold';
  margin-bottom: 40px;
}
.nl-why-linkcxo-video {
  min-height: 100px;
  min-width: 200px;
  width: 680px;
  height: 380px;
}
/* ================why linkcxo end ============== */
/* ================Features start ==================================*/
.nl-main-container-style {
  padding: 80px 0px 0px 0px;
  text-align: center;
  height: auto;
}

.nl-bold-content-style1 {
  color: #61c382;
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 26px;
  margin-top: 15px;
  text-align: right;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.nl-content-style1 {
  font-family: 'Raleway';
  font-size: 15px;
  text-align: right;
}
.nl-bold-content-style {
  color: #61c382;
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 26px;
  margin-top: 15px;
  text-align: left;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.nl-content-style {
  font-family: 'Raleway';
  font-size: 15px;
  text-align: left;
}
.nl-phone-image-style {
  margin-top: -10px;
  height: 460px;
  width: 260px;
}
.nl-gplay-image-style {
  margin: auto;
  display: block;
  margin-top: 20px;
}

.nl-img-style-center {
  width: '60%';
  float: left;
}

.nl-icon-style {
  margin-bottom: 12px;
  margin-top: 15px;
  width: 50px;
  height: 50px;
  display: block;
}
.nl-icon-style1 {
  margin-bottom: 12px;
  margin-top: 15px;
  width: 50px;
  height: 50px;
  display: block;
}
.features-division1 {
  margin: 10px;
  display: inline-block;
  width: 100%;
  padding: auto;
  margin-top: 30px;
}
.text-division1 {
  width: 80%;
  float: left;
  padding-right: 4px;
}
.img-division1 {
  width: 20%;
  float: right;
}

.features-division {
  margin: 10px;
  padding-left: 20px;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  /* padding: auto; */
}

.text-division {
  width: 80%;
  float: right;
}
.img-division {
  /* width: 20%; */
  float: left;
}
.feature-Left-Arrow {
  position: absolute;
  z-index: 99;
  left: -10px;
  top: 635px;
  border: none;
  background: none;
  outline: none;
}
.feature-Right-Arrow {
  position: absolute;
  z-index: 99;
  right: -10px;
  top: 635px;
  border: none;
  background: none;
  outline: none;
}
/* =============================================================Mobile feature=========================================== */

/* ================Features end ============*/
/* ================what our members start====================*/
.nl-what-our-members {
  text-align: center;
}

.main-what-our-member-style-section {
  min-height: 200px;
}
.whats-our-main-card {
  padding: 20px;
  margin-left: 15px;
  /* width: 100%; */
  width: 330px;
  height: 280px;
  border-radius: 5px;
  background-color: #483236;
  margin: auto;
}
.content-over-bg {
  z-index: 99;
  top: 20px;
}
.imgFrame {
  background-color: #39f;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
}
.content-over-bg-pic {
  /* margin-top: -50px; */
  height: 80px;
  width: 80px;
  margin: 0 auto;
  border: 2px solid #fff;
  border-radius: 50%;
  background-size: cover;
  object-fit: cover;
}

/*  */
.what-our-left {
  text-align: center;
}

.what-our-img-right {
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
}
.what-our-left h5 {
  color: #38cd84;
}
.what-our-left p {
  color: #a3a4b3;
}
.what-our-description {
  margin-top: 10px;
  color: white;
  font-size: 14px;
  text-align: center;
  /* padding: 0px 20px; */
}
.custom-Right-Arrow {
  position: absolute;
  z-index: 98;
  right: -3px;
  top: 160px;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow {
  position: absolute;
  z-index: 98;
  left: -10px;
  top: 160px;
  border: none;
  background: none;
  outline: none;
}
.custom-Right-Arrow:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.card-image-style-section {
  float: left;
  margin-left: 50px;
}

/* ================what our members start====================*/

/* ================jobs start====================*/
.nl-jobs {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
  text-align: center;
  margin: auto;
}
.nl-jobs::before {
  content: '';
  background-image: url('../../resources/new-img/Jobs - BG.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}
.main-carousel-style-section {
  min-height: 380px;
  margin: 0 auto;

  /* padding: 0px 20px; */
}

.jobs-style-section {
  margin: 0 auto;
  transition: transform 0.3s;
  width: 100%;
  width: 235px;
  height: 300px;
  border-radius: 10px;
  text-align: center;
  /* margin-left: 30px; */
  /* margin-left: 18px; */
}
.nl-jobs h1 {
  margin-bottom: 20px;
}
.nl-job-card-top {
  width: 235px;
  height: 115px;
  padding: 0px 10px;
  background: #343232;
  text-align: center;
  /* margin: auto; */
}
.nl-job-card-bottom {
  /* margin: 0px 10px; */
  width: 235px;
  height: 130px;
  padding: 5px 10px;
  background: #3e3d3d;
  text-align: left;
  /* margin: auto; */
}
.nl-jobs-style-section-description {
  font-family: 'Raleway';
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
.job-btn {
  /* margin: auto; */
  /* margin: 0px 10px; */
  width: 235px;
  background: #3e3d3d;
  height: 55px;
  padding: 5px 10px;
  border-radius: 0px 0px 5px 5px;
  display: inline-block;
}
.custom-Right-Arrow1 {
  position: absolute;
  z-index: 98;
  right: 0px;
  top: 170px;
  border: none;
  background: none;
  outline: none;
}

.custom-Left-Arrow1 {
  position: absolute;
  z-index: 98;
  left: -3px;
  top: 170px;
  border: none;
  background: none;
  outline: none;
  text-decoration: none;
}
.custom-Right-Arrow1:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow1:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}

/* .MuiSvgIcon-root-20 {
  color: #38cd84;
}
.react-multi-carousel-dot--active button :active {
  background: #4caf50;
} */

.jobs-style-section:hover {
  box-shadow: 0 0 10px 0 #262525;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
.chief-financial-offi-style {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #38cd84;
}
.job-card-data-loaction-style {
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 7px !important;
}

.location-style {
  width: 10px;
  height: 10px;
}
.job-card-data-loaction-style1 {
  color: #39f;
  font-size: 13px;
  line-height: 16px;
  font-family: 'Raleway';
  padding-left: 15px;
}
.arrow-img {
  margin-left: 40px;
  margin-top: 16px;
  height: 50px;
  width: 50px;
}
.desig-img {
  margin-top: 26px;
  height: 32px;
  width: 32px;
}
.event-detail-title {
  margin-top: 5px;
  line-height: 24px;
  font-size: 26px;
  margin-left: 0px;
  padding-left: 0px;
  font-size: 26px;
  font-weight: 600;
  width: 100%;
}
.job-detail-title {
  margin-top: 25px;
  line-height: 33px;
  font-size: 26px;
  margin-left: 0px;
  padding-left: 0px;
}
.job-detail-install {
  line-height: 23px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;
}
.job-detail-gplay {
  margin-top: 16px;
  height: 50px;
  width: 150px;
}
.job-details-container {
  margin-top: 160px;
  height: auto;
  position: relative;
  background-color: #262525;
  color: white;
  padding: '15px';
  text-align: justify;
  padding: 39px 120px;
}
.jobs-apply-header-section {
  margin-top: 80px;
  position: fixed;
  margin-right: 0 !important;
  overflow: hidden;
  float: left;
  width: 100%;
  box-sizing: content-box;
  z-index: 999;
  height: 80px;
  padding: 0px 35px 0px 35px;
  color: #38cd84;
  background-color: #333333;
}

.job-apply-details-btn {
  border: none;
  color: #262525;
  font-family: 'Raleway Semibold';
  font-size: 18px;
  line-height: 19px;
  border-radius: 5px;
  background-color: #61c382;
  padding: 8px 16px;
  margin-top: 24px;
  text-align: center;
  text-decoration: none;
  /* margin-left: 100px; */
  cursor: pointer;
}

.company-name-style-section {
  color: #38cd84;
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 24px;
}
.confidential-style-section {
  color: #fff;
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 23px;
  margin-left: 35px;
  white-space: pre-line;
}
.confidential-style-section1 {
  color: #fff;
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 23px;
  margin-left: 35px;
}
.view-jobs-title-style-section1 {
  background-color: #38cd84;
  color: #262525;
  padding-top: 8px;
}

.job-detail-btn {
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  background-color: #61c382;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
}
.job-apply-btn {
  border: none;
  color: #262525;
  font-family: 'Raleway Semibold';
  font-size: 18px;
  line-height: 19px;
  border-radius: 5px;
  background-color: #61c382;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}
.job-main-padding {
  padding: '0px 80px';
}
.job-count {
  text-align: center;
}
.nl-job-details {
  width: 100%;
  height: 100%;
}
.nl-job-count-sec {
  display: flex;
  /* flex: 1;
  flex-direction: row; */
  flex-wrap: wrap;
}
.count-col1,
.count-col2,
.count-col3 {
  flex: 1;
}
.nl-job-count-sec p {
  font-family: 'Open Sans';
  font-size: 60px;
}
/* ================jobs end====================*/
/* ================Events start ==================================*/
.nl-main-events {
  /* padding-top: 80px; */
  background-image: url('../../resources/new-img/Events - BG.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: 100% 100%;
  text-align: center;
  padding: 30px 0px;
}
.event-carousel {
  padding: 0px 110px;
}
.nl-main-events h1 {
  margin-bottom: 10px;
}
.nl-event-host-icon {
  width: 100%;
  color: #38cd84;
  padding: 0px 80px;
}
.main-event-style-section {
  min-height: 200px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.left-event-image-style-section {
  float: left;
  width: 45%;
  cursor: pointer;
  background-color: '#000';
}
.right-event-image-style-section {
  float: right;
  width: 55%;
}
/* .event-style-section {
  margin: 30px 0px 0px 0px;
  padding: 0px 10px;
  width: 100%;
  border-radius: 10px;
  color: white;
  background: none;
} */
.title-event-style-section {
  margin-top: 8px;
  color: #38cd84;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}
.time-event-style-section {
  margin-right: 30px;
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-family: 'Open Sans';
}
.event-style-section1 {
  width: 546px;
  height: 160px;
  border-radius: 10px;
  margin-left: 30px;
  color: white;
  background: none;
  padding: 20px;
}
.event-image-style-section {
  width: 100%;
  height: auto;
  border-radius: 'none';
}
.event-btn {
  float: left;
}
.btn-event-details {
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
   font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  background-color: #38cd84;
  text-align: center;
  text-decoration: none;
  margin: 0px 10px 0px 0px;
  padding: 5px 30px;
  cursor: pointer;
}
.btn-event-rsvp {
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
   font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  background-color: #38cd84;
  text-align: center;
  text-decoration: none;
  margin: 0px 0px 0px 10px;
  padding: 5px 30px;
  cursor: pointer;
}
.event-Right-Arrow {
  position: absolute;
  z-index: 98;
  right: 0px;
  top: auto;
  border: none;
  background: none;
  outline: none;
}
.event-Left-Arrow {
  position: absolute;
  z-index: 98;
  left: 0px;
  top: auto;
  border: none;
  background: none;
  outline: none;
}
.event-Right-Arrow:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.event-Left-Arrow:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.event-bottom img {
  width: 52px;
  height: 52px;
  margin-bottom: 15px;
}
.event-bottom p {
  color: white;
  font-size: 16px;
  padding: 5px 35px;
}
/* ================Events end ==================================*/
/* ================Partner start ==================================*/

.partner-img-style-section {
  display: flex;
  width: 170px;
  margin-left: 50px;
  margin-top: 0;
}
.partner-logo-section {
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 100px;
}
/* ================Partner end ==================================*/
/* ================Speakers start ==================================*/
.nl-main-speakers {
  background-image: url('../../resources/new-img/Speakers\ -\ BG.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin: auto;
  padding-bottom: 50px;
  background-size: cover;
  text-align: center;
}
.main-meet-speaker-style-section1 {
  min-height: 230px;
}
.meet-speaker-style-section {
  width: 320px;
  height: 290px;
  /* margin-left: 24px; */
  margin: auto;
  border-radius: 10px;
  color: white;
  background: none;
  padding: 15px 15px;
}
.meet-speaker-style-section:hover {
  border: 1px solid #5a5858;
  background: #333;
}
.lorem-ipsum-dolor-style-p1 {
  color: white;
  font-family: 'Raleway';
  font-size: 14px;
  text-align: center;
  margin-bottom: 0 !important;
}
.card-image1-style-section {
  width: 100%;
}
.item-description1-style-section {
  width: 100%;
  float: left;
}
.all-card-name-style {
  margin-bottom: 0 !important;
  color: black;
  font-size: 14px;
  font-weight: bold;
}
.meet-speaker-card-style-section {
  width: 100%;
  float: left;
  background: white;
}
.custom-Right-Arrow2 {
  position: absolute;
  z-index: 98;
  right: 0px;
  top: 125px;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow2 {
  position: absolute;
  z-index: 98;
  left: -5px;
  top: 125px;
  border: none;
  background: none;
  outline: none;
}

.custom-Right-Arrow2:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow2:hover {
  background-color: none;
  border: none;
  background: none;
  outline: none;
}

.card-img1-style-section {
  display: block;
  width: 6em;
  height: 6em;
  margin-left: 100px;
}
/* mobile-view- */

/* ================Speakers end ==================================*/
/* ================Business start ==================================*/
.job-carousel {
  padding: 0px auto;
}
.nl-main-business {
  background-image: url('../../resources/new-img/Business\ -\ BG.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  text-align: center;
  padding-top: 50px;
  padding-left: 120px;
  padding-right: 120px;
}
.business-right {
  text-align: center;
  margin-top: 20px;
}
.business-subtitle p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
}
.business-left img {
  height: 430px;
  width: 380px;
}
.service-content {
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  font-size: 18px;
  margin-right: 5px;
}
.business-logo {
  height: 50px;
  width: 50px;
}
/* ================Business end ==================================*/

/* ================News & Media start ==================================*/

.nl-news-section {
  background-image: url('../../resources/new-img/News&MediaBG.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  text-align: center;
  margin-bottom: 150px;
  padding-top: 50px;
}
.nl-news-section h1 {
  margin-bottom: 40px;
}
.news-image {
  width: 320px;
  height: 184px;
  border-radius: 3px;
}
.news-img-section {
  display: inline-block;
}
.news-style-section {
  display: block;
  width: 340px;
  height: 450px;
  border-radius: 10px;
  margin-left: 35px;
  color: white;
  background: none;
  margin-top: 20px;
}
.news-Right-Arrow {
  position: absolute;
  z-index: 99;
  right: -5px;
  top: 190px;
  border: none;
  background: none;
  outline: none;
}
.news-Left-Arrow {
  position: absolute;
  z-index: 99;
  left: -5px;
  top: 190px;
  border: none;
  background: none;
  outline: none;
}

/* =================News & Media end ==================================*/
/* =================get in touch start ==================================*/
.col-sm-8 {
  margin-right: -3px;
  padding-right: 10px;
  padding-left: 0px;
}
.col-sm-4 {
  margin-right: -5px;
  padding-right: 0px;
}
.firstname {
  margin-left: -15px;
  padding-right: 0px;
}
.lastname {
  margin-left: -7px;
  padding-right: 0px;
}
.main-get-in-touch {
  /* width: 100%; */
  margin: 0px;
  padding: 40px 100px 50px 100px;
  height: auto;
  text-align: center;
  background-color: #262525;
}
.text-get-in-touch {
  margin-left: 15px;
  width: 100%;
  margin-right: 100px;
  padding-top: 110px;
}
.text-get-in-touch p {
  margin-top: 3px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  font-size: 18px;
}
.form-get-in-touch {
  padding-left: 25px;
  font-family: 'Open Sans';
  width: 100%;
  text-align: center;
}
.form-get-in-touch input {
  width: 100%;
  padding: 12px 10px;
  margin: 3px 10px 3px 10px;
  border-radius: 6px;
  font-family: Open Sans;
}
.reaching-out {
  outline: none;
  margin: 3px 10px 3px 12px;
  padding: 12px 10px;
  width: 100%;
  border-radius: 6px;
  font-family: 'Open Sans';
}
.form-get-in-touch textarea {
  width: 100%;
  padding: 10px;
  margin: 4px 10px 30px 12px;
  border-radius: 6px;
  font-family: 'Open Sans';
}

.mobile-get-in-touch {
  font-family: Open Sans;
  margin-top: 2px;
}
.code-get-in-touch {
  color: #6d6868;
  width: 100%;
  font-family: Open Sans;
  margin: 4px 0 2px -5px;
  padding: 12px 10px;
  border-radius: 6px;
  margin-right: 0;
}
.errorMsg {
  color: #38cd84;
}
.btn-form {
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  border-radius: 5px;
  background-color: #38cd84;
  text-align: center;
  margin-top: 30px;
  padding: 8px 25px;
  cursor: pointer;
  margin: auto;
  display: block;
}
/* .screenshot-section {
  padding: 0;
  margin: 0;
} */
/* responsive */
@media only screen and (min-width: 1600px) {
  .html {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  /* .nl-banner {
    height: ;
  } */
  .nl-banner-left-title {
    font-size: 56px;
    line-height: 80px;
  }
  .nl-banner-left {
    margin-top: 140px;
    padding-left: 40px;
  }
  .nl-banner-right {
    padding-left: 140px;
    margin-top: 100px;
  }
  .nl-banner-img-left {
    width: 500px;
    height: 500px;
    margin-left: 0;
    left: 60px;
    position: relative;
  }
  .nl-banner-img-right {
    width: 270px;
    height: 500px;
    position: absolute;
    top: -45px;
    margin-top: 90px;
  }
  .nl-banner-gplay {
    height: 60%;
    width: 30%;
  }
  .nl-why-linkcxo {
    height: auto;
    margin-right: 100px;
    /* margin-top: -100px; */
  }
  .nl-main-container-style {
    margin-top: -250px;
  }
  .nl-phone-image-style {
    margin-top: -50px;
    height: 500px;
    width: 280px;
  }
  .nl-what-our-members {
    margin-top: -20px;
  }
  .nl-main-container-style {
    margin-top: 90px;
    height: auto;
  }
  .nl-main-speakers {
    padding: 0px 80px;
    margin-top: -150px;
  }
  .nl-main-events {
    padding: 0px 10px;
  }
  .nl-about-us {
    padding: 0px 150px;
    margin-top: -50px;
  }
  .nl-what-our-members {
    padding: 0px 20px;
  }
  .what-our-title {
    width: 100%;
    float: left;
    margin-top: 15px;
  }
  .what-our-left {
    padding-left: 0px;
  }
  .what-our-img-right {
    padding-right: 30px;
  }
  .content-over-bg {
    padding: 0;
    margin: 0;
  }

  .nl-jobs {
    padding: 0px 50px;
  }

  .jobs-style-section {
    /* margin-right: 20px; */
    width: 270px;
    height: 340px;
    margin: 0 auto;
  }
  .nl-jobs h1 {
    margin-bottom: 20px;
  }
  .nl-job-card-top {
    width: 290px;
    height: 130px;
    padding: 0px 20px;
    margin: auto;
  }
  .nl-job-card-bottom {
    width: 290px;
    height: 140px;
    padding: 15px 20px;
    margin: auto;
  }
  .job-btn {
    width: 290px;
    margin: auto;
  }

  .custom-Right-Arrow1 {
    top: 160px;
    right: 0px;
  }
  .custom-Left-Arrow1 {
    left: -8px;
  }
  .nl-news-section {
    padding: 0px 90px;
  }
  .nl-get-in-touch {
    padding: 0px 100px;
  }

  .event-style-section {
    margin-left: 0px;
  }
  .nl-main-speakers {
    margin-top: 60px;
    padding-bottom: 30px;
  }
  .meet-speaker-style-section {
    /* margin-left: 70px; */
  }

  .what-our-description {
    padding: 0px;
  }
  .news-style-section {
    margin-left: 90px;
  }
  .metrics-engagement {
    margin-bottom: 40px;
  }
  .seo {
    /* margin-top: 60px; */
    width: auto;
    height: 650px;
    /* border-radius: 5px;
    margin-bottom: 20px; */
  }
  .avgeng {
    /* margin-top: 60px; */
    width: auto;
    height: 650px;
    /* border-radius: 5px;
    margin-bottom: 20px; */
  }
  .avgret {
    /* margin-top: 60px; */
    width: auto;
    height: 600px;
    /* height: 162px;
    border-radius: 5px;
    margin-bottom: 20px; */
  }

  .seo:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .avgeng:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .avgret:hover {
    transform: scale(1.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 500px) {
  .nl-main-feature {
    display: none;
  }
  .company-logo-mob {
    display: none;
  }
  .interest-section-mobile {
    display: none;
  }
  .interest-section-mobile1 {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .item-description1-style-section {
    width: 100%;
    float: left;
    margin: 0px;
    padding: 0px;
  }
  .nl-job-card-top {
    width: 230px;
    height: 120px;
    margin: auto;
  }
  .nl-job-card-bottom {
    width: 230px;
    height: 150px;
    margin: auto;
  }
  .job-btn {
    width: 230px;
    margin: auto;
  }
  .event-image-style-section {
    width: 280px;
    height: 200px;
    margin: 0px;
    padding: 0px;
    border-radius: 10px 0 0 10px;
  }
  .job-apply-details-btn {
    font-size: 15px;
    line-height: 19px;
    padding: 6px 16px;
    margin-top: 0px;
  }
  .event-style-section1 {
    width: 600px;
    height: 170px;
    margin: 0px;
  }
  /* @media only screen and (max-width: 412px) { */
  .event-carousel {
    padding: 0px 10px;
  }
  .job-carousel {
    margin: 0px auto;
    width: 100%;
    padding: 0px 20px;
  }
  .displayhide {
    display: none;
  }
  .html {
    margin: 0 auto;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .custom-Left-Arrow {
    top: 180px;
    left: -10px;
  }
  .custom-Right-Arrow {
    top: 180px;
    right: 0px;
  }
  .feature-Left-Arrow {
    top: 590px;
    left: -2px;
  }
  .feature-Right-Arrow {
    top: 590px;
    right: -2px;
  }
  .event-Left-Arrow {
    top: 80px;
    left: 5px;
  }
  .event-Right-Arrow {
    top: 80px;
    right: 0px;
  }
  .custom-Left-Arrow1 {
    top: 180px;
    left: 7px;
  }
  .custom-Right-Arrow1 {
    top: 180px;
    right: 0px;
  }
  .custom-Left-Arrow2 {
    top: 120px;
    left: 15px;
  }
  .custom-Right-Arrow2 {
    top: 120px;
    right: 30px;
  }

  .news-Right-Arrow {
    top: 220px;
    right: 20px;
  }
  .news-Left-Arrow {
    top: 220px;
    left: 3px;
  }
  h1 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  /* ================Banner============================== */
  .nl-banner {
    margin: 0;
    padding: 0;
    text-align: center;
    height: auto;
  }
  .nl-banner-left-title {
    margin-top: -100px;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
  .nl-banner-right {
    width: 100%;
  }

  /* ================About Us============================== */
  .nl-about-us {
    width: 100%;
    padding: 0px;
    background: none;
    margin: 0;
  }
  .metrics-engagement {
    margin: 30px 0px;
    font-size: 26px;
    color: #38cd84;
  }
  .count-col1 > p,
  .count-col2 > p {
    font-size: 20px;
    color: #38cd84;
  }
  .count-col1 > h4,
  .count-col2 > h4 {
    font-size: 18px;
  }

  .count-col3 > p {
    font-size: 20px;
    color: #38cd84;
  }

  .count-col3 > h4 {
    font-size: 18px;
  }
  .screenshot-section {
    padding: 0;
    margin: 0;
  }
  .nl-about-us-intro {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .nl-about-us-count-sec {
    margin: 0;
    padding: 0;
    /* margin-right: 40px;
    margin-left: 30px;
    margin-top: 0px; */
    /* font-size: smaller; */
    flex-basis: 33.333%;
  }
  .nl-about-us-count-sec p {
    font-family: 'Open Sans';
    font-size: 30px;
  }
  .nl-about-us-count-sec h3 {
    font-size: 20px;
    color: #fff;
  }
  .nl-about-us-vision {
    text-align: center !important;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0;
  }
  .nl-about-us-vision p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    word-break: break-word;
    padding: 0;
  }
  .nl-about-us-vision h3 {
    font-size: 22px;
  }
  /* ================Why-LinkCXO============================== */
  .nl-why-linkcxo {
    text-align: center;
    justify-content: center;
    margin: 0 !important;
    height: auto;
  }
  .nl-why-linkcxo h1 {
    margin-top: 60px;
    /* margin-bottom: -30px; */
  }
  .nl-why-linkcxo-video {
    width: 100% !important;
    height: auto;
    /* margin-bottom: 60px; */
  }
  /* ================Features============================== */
  .nl-main-container-style {
    height: auto;
  }
  .nl-bold-content-style1 {
    text-align: center !important;
  }
  .nl-content-style1 {
    text-align: center !important;
    line-height: 20px;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  .nl-phone-image-style {
    width: 60% !important;
    height: 60%;
  }
  .nl-bold-content-style {
    text-align: center !important;
  }
  .nl-content-style {
    text-align: center !important;
    line-height: 20px;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .nl-icon-style {
    margin-bottom: 5px;
    margin-top: 55px;
    width: 40px;
    height: 40px;
  }
  .nl-icon-style1 {
    margin-bottom: 5px;
    width: 40px;
    height: 40px;
  }

  /* ================What-our-Member============================== */
  .nl-what-our-members {
    margin-top: 0 !important;
  }
  .main-what-our-member-style-section {
    min-height: 100px;
    /* margin-left: 10px; */
  }

  .what-our-title {
    width: 100%;
    float: left;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .what-our-left h5 {
    margin-top: 20px;
  }
  .what-our-left p {
    color: #a3a4b3;
  }
  .what-our-description {
    padding: 0px 5px;
  }

  .nl-what-our-members h3 {
    padding: 0px 25px;
    margin-top: 50px;
  }
  .nl-what-our-members {
    text-align: center;
  }

  .main-what-our-member-style-section {
    min-height: 200px;
  }
  .whats-our-main-card {
    width: 300px;
    height: 300px;
    margin-left: 25px;
    margin: 0 auto;
  }

  .what-our-left p {
    color: #a3a4b3;
  }
  .what-our-description {
    margin-top: 10px;
    color: white;
    font-size: 14px;
    text-align: center;
    /* padding: 0px 20px; */
  }
  /* ================JOBS============================== */

  .nl-jobs {
    width: 100% !important;
    margin: 0 !important;
    height: auto;
    background: none;
    text-align: center;
  }
  .nl-jobs h1 {
    margin-top: 20px;
  }
  .nl-jobs p {
    line-height: 24px;
  }

  .nl-job-count-sec {
    font-size: smaller;
    flex-basis: 33.333%;
  }

  .nl-job-count-sec p {
    font-size: 26px;
    margin-right: 40px;
  }
  .nl-job-count-sec h3 {
    margin-right: 40px;
    font-size: 16px;
  }

  /* ================EVENTS============================== */
  .nl-main-events {
    margin: 0 !important;
    padding: 0px;
    width: 100%;
    height: auto !important;
    background: none;
  }
  .nl-main-events h1 {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  /* .event-style-section {
    display: flex;
  } */
  .left-event-image-style-section {
    margin: 0px;
    padding: 0px;
    text-align: center;

    width: 100%;
  }
  .right-event-image-style-section {
    float: right;
    width: 100%;
  }
  .event-style-section {
    display: block;
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
  .event-style-section1 {
    display: block;
    width: 100%;
    margin: 0 !important;
  }
  .main-event-style-section {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .event-detail-title {
    font-size: 20px;
    line-height: 24px;
  }
  .event-detail {
    font-size: 18px;
    line-height: 22px;
  }
  .title-event-style-section {
    margin: 10px 20px;
    text-align: center;
  }
  .date-event-style-section {
    margin: 0px;
    padding: 0px;
    text-align: center;

  }
  .time-event-style-section {
    font-size: 13px;
    text-align: center;
  }
  /* .event-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  .nl-event-host-icon {
    width: 100%;
    padding: 0px;
  }
  .event-bottom {
    padding-left: 50px;
    padding-right: 20px;
  }
  .event-bottom p {
    text-align: center !important;
    width: 100%;
    font-size: 14px;
    padding: 0;
  }
  .event-bottom h3 {
    font-size: 20px;
  }
  .btn-event-details {
    font-weight: 600;
    font-size: 13px;
    border-radius: 5px;
    background-color: #38cd84;
    padding: 4px 12px;
  }
  .btn-event-rsvp {
   font-weight: 600;
    font-size: 13px;
    border-radius: 5px;
    background-color: #38cd84;
    padding: 4px 12px;
  }
  .nl-our-paertner {
    text-align: center;
    margin: 0 !important;
    height: auto;
  }

  /* ================Our-partners============================== */

  .partner-img-style-section {
    width: 75px;
    margin-top: 0;
    height: 70px;
  }
  .main-partner-style-section1 {
    padding: 0;
    height: auto;
    margin-left: -40px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  /* ================Our-speaker============================== */
  .nl-main-speakers {
    width: 100% !important;
    margin: 0 !important;
  }
  .nl-main-speakers h1 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  /* .main-meet-speaker-style-section1 {
    padding-left: 40px;
  } */
  .meet-speaker-style-section {
    width: 260px;
    height: 300px;
    /* padding: 10px 10px; */
    /* width: 80%; */
  }

  .card-img1-style-section {
    display: block;
    width: 5em;
    height: 5em;
    margin-left: 75px;
    margin-bottom: 20px;
  }
  .lorem-ipsum-dolor-style-p1 {
    font-size: 13px;
    line-height: 17px;
  }

  /* ================Business============================== */
  .nl-main-business {
    background: none;
    padding: 10px 50px;
    text-align: center !important;
  }

  .business-subtitle p {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 0;
  }
  .nl-main-business {
    height: auto;
    padding-top: 30px;
  }
  .business-left img {
    width: 100%;
    height: 98%;
    margin-top: 20px;
  }
  .business-right {
    width: 50%;
    padding: 0;
    padding: 0px 20px;
  }
  .business-service {
    display: inline-block;
    width: 100%;
  }
  .business-service h5 {
    font-size: 16px;
    margin-left: 10px;
  }
  .service-content {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  .business-logo {
    height: 40px;
    width: 40px;
  }
  /* ================News============================== */
  .nl-news-section {
    margin: 0;
    padding: 0 15px;
  }
  .nl-news-section h1 {
    margin: 0px 20px;
    font-size: 24px;
  }
  .news-style-section {
    width: 80%;
    margin-left: 30px;
  }
  .news-image {
    width: 300px;
    height: 180px;
  }

  /* ================Get-in Touch============================== */
  .firstname {
    margin-left: -15px;
    padding-right: 30px;
  }
  .lastname {
    margin-left: -15px;
    padding-right: 30px;
  }
  .main-get-in-touch {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding-left: 20px;
    padding-right: 30px;
  }
  .nl-get-in-touch {
    /* margin-right: 20px; */
    padding: 0;
  }

  .text-get-in-touch {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .text-get-in-touch h2 {
    margin-top: -20px;
    font-size: 26px;
    margin-left: 40px;
  }
  .text-get-in-touch p {
    margin-left: 40px;
    font-size: 14px;
    line-height: 18px;
  }

  .form-get-in-touch input {
    margin-top: 0px;
    width: 100%;
    border-radius: 4px;
    padding: 5px;
    margin-left: 35px;
  }
  .reaching-out {
    margin-top: 2px;
    margin-left: 35px;
    padding: 6px;
    width: 100%;
    border-radius: 3px;
  }
  .code-mobile-row .col-sm-4 {
    margin-left: 25px;
    margin-right: 0px;
  }
  .code-mobile-row .col-sm-8 {
    margin-right: 30px;
  }
  .form-get-in-touch textarea {
    width: 100%;
    border-radius: 3px;
    margin-left: 35px;
    margin-top: 2px;
  }

  .code-get-in-touch {
    margin-bottom: 3px;
    margin-top: 2px;
    margin-right: 20px;
    padding: 5px 6px;
    border-radius: 3px;
  }

  .btn-form {
    margin-left: 120px;
  }
}
@media only screen and (max-width: 764px) {
  .html {
    margin: 0 auto;
  }
  h1 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }

  .nl-banner-img-left {
    left: 15px;
    width: 95%;
    height: 95%;
  }
  /* .nl-banner-img-right {
    left: 210px;
    top: -60px;
    width: 45%;
    height: 90%;
  } */

  .nl-banner-gplay {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .nl-why-linkcxo {
    text-align: center;
    justify-content: center;
    margin: 0 !important;
    height: auto;
  }
  .nl-why-linkcxo h1 {
    margin-top: 60px;
  }
  .nl-why-linkcxo-video {
    width: 100% !important;
    height: auto;
    /* margin-bottom: 60px; */
  }
  .nl-main-container-style {
    width: 100% !important;
    margin-top: 0 !important;
    height: auto;
    padding: 0px;
  }
  .nl-bold-content-style1 {
    text-align: center !important;
  }
  .nl-content-style1 {
    text-align: center !important;
    font-size: 14px;
  }
  .nl-phone-image-style {
    width: 60% !important;
    height: 60%;
    /* margin-top: 50px; */
    /* margin-left: 55px; */
  }
  .nl-bold-content-style {
    text-align: center !important;
  }
  .nl-content-style {
    text-align: center !important;
    /* margin-left: 25px; */
    font-size: 14px;
  }

  .nl-jobs {
    width: 100% !important;
    margin: 0 !important;
    height: auto;
    background: none;
    text-align: center;
  }
  .main-carousel-style-section {
    margin: 0px;
  }
  .jobs-style-section {
    margin-left: 33px;
  }
  .nl-main-events {
    background: none;
    margin: 0 !important;
    padding: 0px 10px;
    height: auto !important;
  }
  .news-image {
    width: 300px;
    height: 180px;
  }
  .nl-job-count-sec {
    margin-right: 0px;
    margin-left: 40px;
    margin-top: -30px;
    /* font-size: smaller; */
    flex-basis: 33.333%;
    /* flex-basis: 50%; */
  }

  .nl-job-count-sec p {
    font-size: 26px;
    /* margin-right: 180px; */
  }
  .nl-job-count-sec h3 {
    /* margin-right: 180px; */
    font-size: 16px;
  }
}

/* ==================Ventures Page CSS ===================================*/
.container-ventures1 {
  padding: 70px 200px 100px;
  background-image: url('../../resources/new-img/banner.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: 100% 100%;
  text-align: center;
}
.venture-main-heading {
  margin-top: 80px;
  margin-bottom: 30px;
  font-size: 35px;
  font-weight: 500;
  color: #fff;
}
.venture-subheading {
  font-size: 30px;
  font-weight: 500px;
  color: #fff;
  margin-bottom: 70px;
}
.venture-btn {
  padding: 16px 31.5px;
  font-size: 18px;
  border-radius: 30px;
  color: white;
  background-color: #262525;
  border: 2px solid #38cd84;
  cursor: pointer;
  font-family: Raleway Medium;
}
.venture-btn:hover {
  color: #262525;
  background-color: #38cd84;
  box-shadow: 0 0 5px #38cd84;
  font-family: Raleway Medium;
}

.container-ventures2 {
  background-color: #262525;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}
.container-ventures2::before {
  content: '';
  background-image: url('../../resources/new-img/BG-form.png');
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: 0;
}

.startup-joining {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 50px 110px 0px 0px;
  text-align: center;
}
.startup-joining1 {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 50px 80px 0px;
  text-align: center;
}
.startup-joining-box {
  width: 235px;
  height: 320px;
}
.startup-icon-row1 {
  width: 235px;
  height: 235px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.startup-icon-row2 {
  width: 235px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.startup-icon-row2 p {
  color: #fff;
  font-size: 18px;
}

.startup-joining-text {
  margin: 0px;
  padding: 0px;
  color: #fff;
}
.startup-icon-row3 {
  margin-top: 30px;
  margin-right: 30px;
  align-items: center;
}
.startup-icon-row3 p {
  margin: 0px 0px;
  color: #fff;
}
.venture-arrow {
  width: 50px;
  height: 50px;
  margin-left: 70px;
}
.startup-icon {
  width: 235px;
  height: 235px;
}

.right-content-venture {
  padding-right: 90px;
  width: 100%;
}
.right-content-venture h3 {
  margin-top: 150px;
  text-align: right;
}
.right-content-venture p {
  margin-left: 40px;
  margin-top: 30px;
  color: #fff;
  font-size: 18px;
  line-height: 35px;
  margin-top: 30px;
  text-align: right;
}
.left-img-venture {
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-img-venture img {
  width: 480px;
  height: 480px;
  margin-top: 80px;
}
.left-process-venture {
  float: left;
  padding: 40px 30px;
  text-align: left;
  font-size: 20px;
  color: #38cd84;
}
.right-process-venture {
  float: right;
  padding: 40px 50px;
  text-align: right;
  font-size: 20px;
  color: #38cd84;
}
.startup-process {
  margin: 30px 100px 0px;
}
.startup-process-text {
  margin: 0px 100px;
}
.startup-icon-row3 {
  margin-top: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.startup-icon-row3 img {
  background: #38cd84;
  width: 150px;
  height: 150px;
  padding: 20px;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}
.startup-icon-row4 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.startup-icon-row4 p {
  font-size: 18px;
  padding: 0px 20px;
  color: #fff;
}

.benefits-btn {
  width: 100%;
  display: block;
  height: 50px;
}
.venture-btn1 {
  margin-top: 15px;
  margin-bottom: 70px;
  padding: 10px 24px;
  font-size: 18px;
  border-radius: 30px;
  color: #262525;
  font-family: Raleway Medium;
  background-color: #38cd84;
  border: 2px solid #262525;
  cursor: pointer;
}
.venture-btn2 {
  margin-top: 30px;
  padding: 10px 35px;
  font-size: 22px;
  border-radius: 25px;
  color: #262525;
  font-family: Raleway Medium;
  background-color: #38cd84;
  border: 2px solid #262525;
  cursor: pointer;
  margin: auto;
  display: block;
}
.container-ventures3 {
  padding: 120px 120px;
  background-color: #262525;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  text-align: center;
}
.container-ventures3::before {
  content: '';
  background-image: url('../../resources/new-img/BG-form.png');
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}
.heading-startup {
  color: #38cd84;
}
.form-control {
  color: #fff;
  background-color: #5a5858;
  border: 1px solid #5a5858;
}
.form-startup {
  text-align: left;
}
.form-group {
  padding: 0px 120px;
}
.mobile-in-mobile {
  margin-top: 32px;
}
/* .first_name_row {
  margin-right: -10px;
}
.last_name_row {
  margin-left: -8px;
} */

.nl-success {
  /* background-image: url("../../resources/new-img/Success_BG.png");
  background-repeat: no-repeat; */
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
}
.nl-success-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 110px;
  height: 100vh;
  text-align: center;
}
.success-heading {
  padding: 0px 45px;
}
.success-heading p {
  font-size: 30px;
  line-height: 38px;
}
.success-subheading {
  margin-top: 20px;
  margin-bottom: 20px;
}
.success-subheading p {
  font-size: 26px;
  line-height: 31px;
  padding-right: 30px;
}
.success-content {
  margin-top: 20px;

  text-align: left;
}
.success-content p {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
}
.dot {
  margin-top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
}
.success-btn {
  padding: 12px 25px;
  font-family: Raleway Medium;
  font-size: 24px;
  border-radius: 8px;
  color: #262525;
  font-family: Raleway Medium;
  background-color: #38cd84;
  border: 2px solid #262525;
  cursor: pointer;
  margin: auto;
  display: block;
}
/* mobile view of job detail*/
@media only screen and (max-width: 500px) {
  /* @media only screen and (max-width: 412px) { */
  .jobs-apply-header-section {
    margin-top: 58px;
    height: auto;
    padding: 10px 20px 0px 15px;
    /* margin: 0px; */
    width: 100%;
  }
  .img1-job {
    display: none;
  }
  .img2-job {
    display: none;
  }

  .img3-job {
    margin: 0px;
    padding: 0px;
  }
  .job-details-container {
    width: 100%;
    text-align: left;
    margin-top: 170px;
    padding: 30px 0px 0px 30px;
  }
  .arrow-img {
    margin-left: 0px;
    margin-top: 0px;
    height: 20px;
    width: 20px;
  }
  .desig-img {
    margin-left: -30px;
    margin-top: 0px;
    height: 20px;
    width: 20px;
  }
  .job-detail-title {
    margin-right: 0px;
    margin-top: 10px;
    line-height: 20px;
    font-size: 16px;
    width: 100%;
  }
  .job-detail-install {
    line-height: 17px;
    margin-top: 10px;
    font-size: 12px;
    margin-left: 0px;
  }
  .job-detail-gplay {
    padding: 0px;
    margin-top: 0px;
    height: 40px;
    width: 110px;
    margin-left: 10px;
  }
  .job-detail-row {
    margin-top: -25px;
  }
  .job-details-col {
    margin-bottom: 20px;
  }
  .company-name-style-section {
    font-size: 15px;
  }
  .confidential-style-section1 {
    font-size: 15px;
  }
  .confidential-style-section {
    font-size: 15px;
  }
  .nl-success {
  }
}
/* mobile view of ventures*/
@media only screen and (max-width: 500px) {
  /* @media only screen and (max-width: 412px) { */
  .container-ventures1 {
    background-image: none;
    background-color: #262525;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
  }
  .startup-joining {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .startup-icon {
    width: 200px;
    height: 200px;
    margin-left: 80px;
  }
  .form-check {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .venture-arrow {
    transform: rotate(90deg);
  }
  .container-ventures3 {
    margin-top: 50px;
  }
  .container-ventures2 {
    background-image: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .container-ventures3 {
    padding: 0px 20px 0px 0px;
  }
  .container-ventures2 h3 {
    margin-top: 130px;
  }
  .venture-main-heading {
    padding-top: 80px;
  }
  .bulb-venture {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    width: 100%;
  }
  .startup-heading h3 {
    margin-top: 30px;
  }
  /* .bulb-venture {
    margin-top: -40px;
  } */
  .left-img-venture {
    width: 100%;
    /* margin-top: -90px; */
    padding: 0;
    text-align: center;
    /* height: auto; */
  }
  .right-content-venture {
    display: block;
    margin-left: -100px;
    padding-right: 40px;
    width: 100%;
    height: auto;
  }
  .right-content-venture div {
    display: none;
  }
  .right-content-venture h3 {
    text-align: center;
    margin: 0;
    padding: 0px 0px 0px 150px;
  }
  .right-content-venture div {
    display: none;
  }
  .right-content-venture p {
    width: 100%;
    margin-left: 65px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    padding: 0px;
  }
  .startup-joining {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-joining-box {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row1 {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row1 img {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row1 p {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row2 {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row2 img {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row2 p {
    margin: 0;
    padding: 0;
    height: auto;
  }

  .startup-icon-row3 {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row3 img {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row3 p {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row4 {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row4 img {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .startup-icon-row4 p {
    margin: 0;
    padding: 0;
    height: auto;
  }
  .venture-main-heading {
    color: #38cd84;
    margin-top: 40px;
    font-size: 28px;
    font-weight: 400px;
    width: 100%;
  }
  .venture-subheading {
    font-size: 24px;
    font-weight: 400px;
    margin-bottom: 20px;
    width: 100%;
  }
  .venture-btn {
    margin: 20px 0px;
    padding: 8px 26px;
    font-size: 16px;
    border-radius: 20px;
  }
  .btn-venture-section {
    margin-top: 30px;
  }
  .form-venture {
    margin: 0px 10px;
    padding: 0px 10px;
  }
  .form-venture form {
    margin: 10px;
  }
  .form-group {
    width: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
  }

  .form-group label {
    float: left;
    font-size: 16px;
    margin-top: 15px;
    color: #38cd84;
  }
  .heading-startup {
    margin-top: 30px;
  }

  .mobile-in-mobile {
    margin-top: 50px;
    margin-left: 30px;
  }
  .mobile-row {
    display: inline-block;
  }
  .col-sm-2 {
    margin-right: 180px;
    margin-left: 5px;
  }
  .col-sm-10 {
    margin-top: -61px;
    margin-left: 95px;
    margin-right: 0px;
  }

  .nl-success {
    background-image: none;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .success-heading {
    margin-top: 20px;
  }
  .success-heading p {
    font-size: 24px;
    line-height: 32px;
  }
  .success-subheading {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .success-subheading p {
    font-size: 20px;
    line-height: 28px;
  }

  .success-content p {
    font-size: 16px;
    line-height: 22px;
    margin-left: 10px;
  }

  .dot {
    display: none;
  }
  .checkbox-input {
    margin-left: 140px;
  }
}

/* css -badal */
.venture-btn-link {
  padding: 10px 0px;
  color: white;
  text-decoration: none;
  font-family: Raleway Medium;
}
.venture-btn1-link {
  color: #262525;
  text-decoration: none;
  font-family: Raleway Medium;
}
.venture-btn1-link:hover {
  color: #262525;
  text-decoration: none;
  font-family: Raleway Medium;
}
.venture-btn-link:hover {
  color: #262525;
  text-decoration: none;
  font-family: Raleway Medium;
}
.header-link {
  color: white;
  text-decoration: none;
}
.header-link:hover {
  color: white;
  text-decoration: none;
}
.nave-bar-menu-style-link {
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
}
.nave-bar-menu-style-link:hover {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
}
.nave-bar-join {
  color: black;
  text-decoration: none;
}
.nave-bar-join:hover {
  color: black;
  text-decoration: none;
}
.nave-bar-menu-style-link-join {
  color: black;
  text-decoration: none;
}
.nave-bar-menu-style-link-join:hover {
  color: white;
  text-decoration: none;
}

/* ================ABOUT US page ================== */
.top-section {
  background: url('../../resources/new-img/aboutus1bg.jpg') center center
    no-repeat;
  width: 100%;
  height: 100vh;
  padding: 0px 130px;
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
}
.top-section:before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  /* width: 100%;
  height: auto;
  background-size: cover;
  background-position: center; */
  background-color: rgba(38, 37, 37, 0.85);
}
.company-story {
  background-image: url('../../resources/new-img/News&MediaBG.png');
  background-repeat: no-repeat;
  padding: 0px 100px;
  width: 100%;
  height: auto;
  padding: 50px;
  /* background-size: cover; */
  background-size: 110% 100%;
}

.about-sec2 {
  margin: 0px 80px;
  /* margin-top: 200px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 4px solid #38cd84;
  padding-top: 30px;
}

.white-circle {
  background-color: #fff !important;
  margin-top: -45px;
  width: 25px;
  height: 25px;
  line-height: 45px;
  border-radius: 50%;
  /* z-index: 99; */
}
.team-section {
  background-image: url('../../resources/new-img/bg.png');
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-top: 30px;
  background-size: cover;
}
.team {
  margin-top: 40px;
}
.people {
  padding: 0px 50px;
}
.people4 {
  padding: 0px 100px;
}
.people1 {
  /* margin-left: 20px;
  max-width: 47% */
}
.people2 {
  margin-right: 20px;
}
.people-img {
  margin-top: 20px;
  /* background-color: #fff; */
  border-radius: 5px;
  width: auto;
  height: 180px;
}
.color-white {
  color: #fff;
  margin-top: -20px;
}
.color-green1 {
  margin-top: 10px;
  padding-bottom: 4px;
  color: #38cd84;
  font-size: 18px;
  font-weight: 500;
}
.metrics-engagement {
  font-weight: 800;
}
.join-team {
  padding: 50px 150px;
  background-color: #333;
  text-align: center;
}
/* ================PARTNERs page ================== */
.partner-top-section {
  background-image: url('../../resources/new-img/Artboard.png');
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  height: auto;
  margin: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.gift-img {
  width: 90px;
  height: 90px;
}
.partner-mob-section1 {
  display: none;
}
.partner-rows {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.partner-rows1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.partner-heading {
  color: #38cd84;

  /* margin-top: -40px; */
}
.partner-benefit-section {
  padding: 50px 0px;
}
.PartnerBtn-section {
  text-align: center;
  padding: 40px 150px 20px;
}
.benefit-partner {
  font-size: 18px;
  color: #38cd84;
}
.partner-benefit {
  font-size: 16px;
  color: #fff;
}
.partner-btn {
  border: none;
  color: black;
  font-family: 'Raleway SemiBold';
  font-size: 20px;
  line-height: 19px;
  border-radius: 5px;
  background-color: #61c382;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.logo_row2 {
  padding: 0px 100px 0px 150px;
}
.partner-txt {
  color: #fff;
  font-size: 20px;
}
.partner-box {
  width: 530px;
  height: 120px;
  object-fit: contain;
  margin: 0px 5px;
  margin-bottom: 10px;
  background-color: #333;
  padding: 4px 10px;
  border: 2px solid #828282;
  border-radius: 4px;
}
.partner-box1 {
  width: 440px;
  height: 120px;
  object-fit: contain;
  margin: 0px 5px;
  margin-bottom: 10px;
  background-color: #333;
  padding: 4px 10px;
  border: 2px solid #828282;
  border-radius: 4px;
}
.seo-ranking {
  padding: 0px 100px;
}
.seo {
  margin-top: 60px;
  /* width: 330px; */
  height: 234px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.avgeng {
  margin-top: 60px;
  /* width: 380px; */
  height: 234px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.avgret {
  margin-top: 60px;
  width: 450px;
  height: 162px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.seo:hover {
  transform: scale(1.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.avgeng:hover {
  transform: scale(1.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.avgret:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

/* About us new */

.main-timeline-section {
  position: relative;
  width: 100%;
  margin: auto;
  height: 300px;
  margin-left: 10%;
  margin-top: 42px;
}
.main-timeline-section .timeline-start,
.main-timeline-section .timeline-end {
  position: absolute;
  background: #38cd84;
  border-radius: 100px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
}
.main-timeline-section .timeline-end {
  right: 0px;
}
.container-company-story {
  width: 100%;
  text-align: center;
}
.main-timeline-section .conference-center-line {
  position: absolute;
  width: 80%;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #38cd84;
  /* margin-left: 10%; */
}
.timeline-article {
  width: 20%;
  position: relative;
  min-height: 300px;
  float: right;
}
.timeline-article .content-date-bottom {
  position: absolute;
  left: -32px;
  font-size: 24px;
  font-weight: bolder;
  color: #ffffff;
  border-radius: 50%;
  background-color: #262525 !important;
  color: #fff;
  margin-top: 230px;
  width: 85px;
  height: 85px;
  border: 4px solid #38cd84;
  line-height: 76px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
.content-date-text {
  padding: 16px;
}
.content-date-text p {
  line-height: 16px;
  margin-bottom: 4px;
  top: 50%;
  font-size: 20px;
  font-weight: bolder;
  /* padding-top: 8px; */
}
.timeline-article .content-date {
  position: absolute;
  left: -32px;
  font-size: 16px;
  font-weight: bolder;
  color: #ffffff;
  border-radius: 50%;
  background-color: #262525 !important;
  color: #fff;
  /* margin-top: -45px; */

  width: 85px;
  height: 85px;
  border: 4px solid #38cd84;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
.date-dotted-line-top {
  border-left: 3px dashed #ffffff;
  height: 60px;
  width: 10px;
  margin-left: 9px;
  padding-top: 140px;
}

.date-dotted-line-bottom {
  border-left: 3px dashed #ffffff;
  height: 80px;
  width: 10px;
  margin-left: 8px;
  margin-top: 156px;
}
.timeline-article .meta-date {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #38cd84;
}
.timeline-article .content-box {
  color: #fff;
  font-family: Raleway;
  width: 200px;
  position: absolute;
  top: 55%;
  left: -80px;
  padding: 10px;
}

.timeline-article-bottom .content-date {
  top: 59%;
}
.timeline-article-bottom .content-box {
  top: 10%;
}
.timeline-article-bottom .content-box:before {
  /* content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  border: 10px solid transparent;
  border-top-color: #38cd84; */
}

.company-story-team {
  text-align: center;
}
.company-logo {
  background-color: #333;
  text-align: center;
  margin-top: 30px;
  padding: 50px 0px;
}
.company-logo h2 {
  margin-bottom: 30px;
}
.company-logo-mob {
  background-color: #333;
  text-align: center;
  margin-top: 0px;
  padding: 0px;
}
.company-logo-mob h4 {
  margin-bottom: 30px;
}
.imgFrame {
  background-color: #333;
  width: 100px;
  height: 100px;
  /* margin-left: 30px; */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  text-align: center;
}
.logo_img {
  /* margin-left: 30px; */
  object-fit: contain;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  /* border-radius: 50%; */
  background-size: cover;
}
.imgFrame1 {
  background-color: #333;
  width: 75px;
  height: 75px;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  text-align: center;
}
.logo_img1 {
  object-fit: contain;
  margin: 0 auto;
  width: 65px;
  height: 65px;
  /* border-radius: 50%; */
  background-size: cover;
}

.top-section-title {
  margin-top: 40px;
  font-size: 45px;
}
.partner-logo-section-mob {
  display: none;
}
.partner-top-section1 {
  display: none;
}
.partner-top2 {
  background-color: #262525;
  padding-top: 100px;
  padding-bottom: 20px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .top-section-title {
    margin-top: 60px;
  }
  .interest-row12 {
    display: none;
  }
  .interest-row22 {
    display: none;
  }
  .partner-top2 {
    padding-top: 65px;
    padding-bottom: 20px;
    text-align: center;
  }
  .partner-heading {
    margin-top: 50px;
    font-size: 26px;
  }
  .partner-top-section {
    display: none;
  }
  .partner-benefit-section {
    padding: 0;
    margin: 0px 0px 0px 15px;
    width: 100%;
  }
  .partner-logo-section-mob {
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .partner-logo-section {
    display: none;
  }
  .PartnerBtn-section {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .partner-txt {
    margin-top: 20px;
  }
  .imgFrame {
    width: 100px;
    height: 100px;
  }
  .logo_img {
    width: 90px;
    height: 90px;
  }
  .imgFrame1 {
    width: 55px;
    height: 55px;
  }
  .logo_img1 {
    width: 45px;
    height: 45px;
  }

  .company-logo-mob {
    margin-top: 10px;
    padding: 0px 0px 0px 20px;
  }
  .name {
    margin-top: 10px;
    width: 58px;
    height: auto;
  }
  .people4 {
    padding: 0px;
  }
  /* ================ABOUT US page ================== */
  .top-section {
    background: none;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 35px 0px 0px 0px;
  }
  .top-section h1 {
    font-size: 24px;
  }

  .company-story {
    display: none;
  }

  .join-team {
    padding: 10px;
    background-color: #333;
    text-align: center;
  }
  .seo-ranking {
    padding: 0px;
  }
  .seo {
    margin-top: 60px;
    width: 320px;
    height: 180px;
    padding: 0;
    margin: 0;
    /* border-radius: 5px;
    margin-bottom: 20px; */
  }
  .avgeng {
    margin-top: 60px;
    width: 320px;
    height: 165px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .avgret {
    margin-top: 60px;
    width: 340px;
    height: auto;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .seo:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .avgeng:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .avgret:hover {
    transform: scale(1.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  /* ================Partners Logo ================== */
  .partner-top-section1 {
    background-image: none;
    text-align: center;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .partner-box {
    padding: 0;
    width: 100%;
    /* width: 370px;
    height: 112px; */
  }
  .partner-box1 {
    padding: 0;
    /* width: 330px;
    height: 112px; */
  }
  .benefit-partner {
    font-size: 16px;
    color: #38cd84;
  }
  .partner-benefit {
    font-size: 14px;
    color: #fff;
  }
  .partner-logo-section {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .logo_row1 {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-left: 100px;
  }
  .logo_row2 {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-left: 100px;
  }
  .interest-section-mobile {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .interest-section-mobile1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .interest-row1 {
    margin: 0;
    padding: 0px 0px;
    text-align: center;
  }
  .interest-title {
    margin-top: 20px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .interest-section {
    display: none;
  }
}
.opensansfont {
  font-family: 'Open Sans';
  padding-top: '4px';
}

.contentmonth {
  font-size: 16px !important;
}

.mission-div-align {
  padding: 0px 30px !important;
}

.vision-div {
  margin-left: 4.5%;
}

.mission-div {
  max-width: 37%;
  margin-right: 5%;
}

.join-our-team {
  font-weight: bolder;
}

/* ================Interest Logo ================== */

.interest-section {
  text-align: center;
  padding: 50px 0px;
}
.industry-section {
  text-align: center;
  padding: 50px 0px;
}
.interest-title {
  color: #38cd84;
  margin-bottom: 40px;
}
.interest-row1 {
  text-align: center;
  padding: 20px 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.interest-row11 {
  text-align: center;
  padding: 20px 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.logo-frame {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interest-row2 {
  padding: 20px 200px;
  display: flex;
  align-items: flex-start;
}
.interest-row12 {
  padding: 20px 150px;
}
.interest-row22 {
  padding: 20px 200px;
}
.industries-row12 {
  padding: 20px 100px;
}
.industries-row22 {
  padding: 20px 150px;
}
.interest-frame {
  margin-bottom: 20px;
  background: rgba(56, 205, 132, 0.25);
  border: 2px solid #38cd84;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
}
.interest-img {
  object-fit: contain;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background-size: cover;
}
.desigRow {
  padding: 0px 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.green-box {
  text-align: center;
  /* width: auto; */
  height: 35px;
  object-fit: contain;
  margin: 0px 5px;
  margin-bottom: 15px;
  background-color: rgba(56, 205, 132, 0.25);
  padding: 4px 10px;
  border: 2px solid #38cd84;
  border-radius: 4px;
}
.designation {
  width: auto;
  color: #fff;
}
.interest-frame1 {
  margin-bottom: 25px;
  background: rgba(56, 205, 132, 0.25);
  border: 2px solid #38cd84;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
}
.interest-img1 {
  object-fit: contain;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background-size: cover;
}
/* ========================Benefit Section================================= */
.nl-Benefits {
  /* background-image: url("../../resources/new-img/bg.png");
  background-repeat: no-repeat; */
  width: 100%;
  height: auto;
  margin: 0px;
  text-align: center;
  padding: 10px 80px;
}
.benefits-heading {
  margin: 80px 0px 30px;
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
  color: #38cd84;
}
.benefits-subheading {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: #38cd84;
}
.benefit-frame {
  margin-bottom: 20px;
  background: rgba(56, 205, 132, 0.25);
  border: 2px solid #38cd84;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
}

.benefit-img {
  object-fit: contain;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  background-size: cover;
}
.benefits-content {
  width: 100%;
  height: 70px;
  font-size: 15px;
  color: #fff;
  line-height: 22px;
}
.active {
  background-color: #1c4586;
  width: 120px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  padding: 6px 0px;
  border-radius: 5px;
  height: 36px;
  margin-top: -8px;
  /* align-items: center; */
  display: block;
  /* justify-content: center; */
}
.active:hover {
  background-color: #1c4586;
  width: 120px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  padding: 6px 0px;
  border-radius: 5px;
  height: 36px;
  margin-top: -8px;
  /* align-items: center; */
  display: block;
}
.active2 {
  background-color: #1c4586;
  width: 100px;
  text-decoration: none;
  /* padding: 3px 0px; */
  border-radius: 5px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 500px) {
  .nl-Benefits {
    /* background-image: url("../../resources/new-img/bg.png");
  background-repeat: no-repeat; */
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .benefits-heading {
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .benefits-subheading {
    font-size: 16px;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .benefits-content {
    font-size: 14px;
    color: #fff;
    line-height: 18px;
  }
}
