.headereditor .ql-editor {
  background-color: #262525;
  color: white !important;
  min-height: 6em;
}

.headereditor .ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f; /* <== change this color */
  color: #f8f8f2;
  overflow: visible;
}
.headereditor .quill .ql-snow .ql-container {
  border: none;
  background: #1c1c1c;
}
.headereditor .editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #1c1c1c;
  border: none;
  color: #ffffff;
}
.headereditor .ql-picker {
  color: #ffffff !important;
}
.headereditor .ql-picker-item {
  color: #000000;
}
.headereditor .ql-picker-item:hover {
  color: #38cd84 !important;
}
.headereditor .quill {
  border: none;
}
.headereditor .quill .ql-container.ql-snow {
  border: none;
}
.headereditor .ql-toolbar.ql-snow {
  background: #1c1c1c;
  border-left: none;
  border-right: none;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.headereditor .ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: #38cd84 !important;
}
.headereditor .ql-snow.ql-toolbar button.ql-active {
  color: #38cd84 !important;
}
.headereditor .ql-snow .ql-stroke {
  stroke: #ffffff;
}
.headereditor .ql-snow .ql-fill {
  fill: #ffffff;
}
.headereditor .ql-bold .ql-active {
  stroke: #38cd84 !important;
}
.headereditor .ql-snow.ql-toolbar button:hover {
  stroke: #38cd84 !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  color: #707070;
}
