@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
/* @font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../../fonts/Raleway-Medium.ttf) format('.ttf');
 } */

html {
  font-family: Raleway, Raleway SemiBold, Open Sans;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Raleway;
}
p {
  font-weight: normal;
  font-size: 15px;
}

/* img{   width: 100%;  height: 100% } */
.MuiDialogContent-root-77 {
  overflow: hidden;
  padding: 0 !important;
}
.MuiDialogContent-root-70 {
  overflow: hidden;
  padding: 0 !important;
}
.full-width {
  width: 100%;
  float: left;
}

.half-width-l {
  width: 50%;
  float: left;
}

.half-width-r {
  width: 50%;
  float: right;
}

.headeractive {
  background-color: #1c4586;
  color: white;
  display: inline-block;
  border-radius: 5px;
  padding: 6px 14px;
  font-size: 16px;
  font-family: Raleway Medium;
}
.wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.textField {
  width: 100%;
  float: left;
}
.jobs-apply-header-section {
  background-color: #333333;
}
.textField input[type='text'] {
  width: 100%;
  float: left;
  padding: 10px;
  outline: none;
}

.textField textarea {
  width: 100%;
  float: left;
  padding: 10px;
  outline: none;
}
.numbers {
  font-family: 'Open Sans';
}
#no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: hidden;
}

#image-logo-section-style {
  max-width: 100%;
  max-height: 60px;
  display: inline-block !important;
}
.nl-footer {
  padding: 20px 0px 0px 80px;
  display: flex;
}
.address-style-sections {
  margin-top: 15px;
  color: #fff;
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 23px;
}
.play-style-section1 {
  width: 125px;
  height: 125px;
  margin-top: 59px;
}

.download-style-section {
  color: #3dcd84;
  font-family: 'Gill Sans';
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
}
.logo-image-section-style {
  width: 195px;
  height: 60px;
}
.logo-image-section {
  margin-left: 80px;
  cursor: pointer;
  padding-bottom: 10px;
}
.play-store-section-style {
  display: inline;
}

.footer-logo-section-style {
  display: inline;
  margin-top: 55px;
}
.dwonload-play-style {
  padding-top: 79px;
}
.navbar-toggler-icon {
  text-align: right;
  border: solid 1px white;
  box-shadow: 0 0.35em 0 0 #fff, 0 0.7em 0 0 #fff;
  background-color: #fff;
  top: -2.5em;
  width: 1em;
  height: 0.1em;
  vertical-align: middle;
  position: relative;
  padding-left: 1.45em;
  float: right;
}

@media only screen and (max-width: 500px) {
  .privacy-policy {
    padding: 0px 40px;
    text-align: justify;
    text-justify: distribute;
  }
  .navbar-toggler-icon {
    left: 10px;
    top: -10px;
    border: 1px solid white;
  }
  .nav-style {
    margin: 0;
    text-align: center;
  }
  .logo-image-section {
    padding: 0;
    margin-top: -5px;
    margin-bottom: -10px;
    margin-left: -20px;
  }

  .logo-image-section-style {
    width: 90%;
  }
  .justify-content-end {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .nl-footer {
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-logo-section-style {
    height: 90px;
    display: inline;
  }

  .address-style-sections {
    padding-top: 50px;
    line-height: 19px;
  }
  .button-events-style-section {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 20px;
  }
  .download-style-section {
    margin-top: -40px;
    padding: 0px;
  }
  .play-style-section {
  }
  .play-style-section1 {
    padding: 0;
  }
}
element.style {
  cursor: pointer;
  opacity: 0.5;
  background: transparent;
  border: none;
  fill: black;
  color: white;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  outline: none;
}

.main-header-style-section {
  position: fixed;
  z-index: 99;
  background: #1f1e1e;
  margin-right: 0 !important;
  overflow: hidden;
  float: left;
  width: 100%;
  box-sizing: content-box;
}

.padding-right-style {
  padding-right: -10px !important;
  margin-right: 0 !important;
}
.nave-bar-menu-style {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-top: 8px;
  font-family: 'Raleway Medium';
}
.nave-bar-menu-style-new {
  color: #38cd84;
  font-size: 26px;
  margin-bottom: 10px;
  font-family: 'Raleway Medium';
}
/* -------------------banner CSS    Start------------------------- */
/* .carousel-style-container {
  width: 100%;
  height: 600px;
}
.main-style-carousel-section {
  height: 640px;
} */
/* .text-container-block {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+1.png")
      no-repeat center;
  position: relative;
  font-family: Arial;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 640px;
  filter: blur(0px);
  -webkit-filter: blur(0px);
} */
@media only screen and (max-width: 1280px) {
  .text-container-block {
    width: 100%;
    margin-top: 20%;
    height: 750px;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+1.png')
        no-repeat center;
  }
  .text-container-block1 {
    width: 100%;
    margin-top: 20%;
    height: 750px;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+2.png')
        no-repeat center;
  }
  .text-container-block2 {
    width: 100%;
    margin-top: 20%;
    height: 750px;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Landing-Stock.png')
        no-repeat center;
  }
}
@media only screen and (max-width: 800px) {
  .text-container-block {
    width: 100%;
    margin-top: 20%;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+1.png')
        no-repeat center;
  }
  .text-container-block1 {
    width: 100%;
    margin-top: 20%;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+2.png')
        no-repeat center;
  }
  .text-container-block2 {
    width: 100%;
    margin-top: 20%;
    background: linear-gradient(rgba(46, 45, 45, 0.45), rgba(46, 45, 45, 0.45)),
      url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Landing-Stock.png')
        no-repeat center;
  }
  .imageSliderbanner {
    height: 180px;
    width: 100%;
  }
  .content-text-style-dark {
    width: 100%;
    margin: 100px 0 0 0;
    line-height: 25px;
  }
  .content-text-style-dark-h2 {
    font-size: 1.2rem;
    line-height: 35px;
  }
  .text-color-style {
    font-size: 12px;
  }
  .text-Niche-style {
    font-size: 20px;
    padding-left: -50px;
  }
  .content-fee-style-fee {
    font-size: 13px;
  }
  .content-weekend-style-week {
    font-size: 12px;
  }
  .image-banner-style-container-style {
    width: 80%;
    margin: 30px;
  }
  .slider-style-container {
    width: 100%;
    margin-bottom: -40%;
  }
  .banner-image-container-style {
    height: 25%;
    width: 15%;
  }
  .wrapper-card-testimonials {
    width: 100%;
    margin-bottom: 37%;
    height: 300px;
  }
}

.text-block-image-text {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}
.main-icon-style-setion {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #262525;
}
.main-icon-style-setion:hover {
  background-color: #38cd84;
}
.image-banner-style-container-style {
  top: 30%;
  position: absolute;
  width: 40%;
  float: left;
  color: white;
}
.image-banner-style-container-style1 {
  top: 20%;
  right: -450px;
  position: absolute;
  width: 40%;
  float: left;
  color: white;
}

.paging-dot {
  color: white;
  margin-left: 10px;
  fill: white;
  border: none;
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .image-banner-style-container-style {
    width: 50%;
    left: 9%;
  }
}

@media only screen and (max-width: 900px) {
  .image-banner-style-container-style {
    width: 60%;
    position: absolute;
    top: 16%;
  }
  .image-banner-style-container-style1 {
    position: absolute;
    top: 20%;
    right: -95px;
  }
}

.buttonTrue {
  border: none;
  padding: 5px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: none;
  color: white;
  outline: none;
  cursor: pointer;
}

.content-text-style-dark {
  width: 100%;
  text-align: left;
  font-weight: normal;
  color: white;
  filter: blur(0px);
  margin-left: 8px;
  float: left;
  font-size: 21px;
  margin-top: 80px;
  line-height: 55px;
}

.text-container-block1 {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Banner+2.png')
      no-repeat center;
  position: relative;
  font-family: Arial;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 640px;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
.text-container-block2 {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Landing-Stock.png')
      no-repeat center;
  position: relative;
  font-family: Arial;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 640px;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

.paging-dot {
  color: white;
}

.pageing-dots > .dot {
  background: red;
}

.flickity-page-dots > .dot.is-selected {
  background: rgb(0, 255, 255);
}

.flickity-button {
  opacity: 0.5;
}
.button-events-style-section {
  border: none;
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 16px;
  border-radius: 5px;
  background-color: #38cd84;
  padding: 4px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
.button-events-style-section1 {
  border: none;
  color: #fff;
  font-family: 'Raleway';
  font-size: 16px;
  border-radius: 5px;
  background-color: #1c4586;
  padding: 6px 15px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
/* -------------------About_Us CSS    Start------------------------- */

.main-about-style-section {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/About+Us.png')
    no-repeat center;
  background-size: cover;
}

.about-us-style-p {
  color: #262525;
  font-weight: 770;
  font-family: sans-serif;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  padding-left: 20px;
}

.about-us-style-p1 {
  color: #000;
  font-family: 'Raleway Medium';
  font-size: 18px;
  line-height: 23px;
  padding-left: 20px;
}

.about-us-image-style1 {
  border-radius: 10px;
  border: 1px solid #262525;
}

.about-us-image-style2 {
  border-radius: 10px;
  border: 1px solid #262525;
}

.about-us-image-style3 {
  border-radius: 10px;
  border: 1px solid #262525;
  background-size: cover;
  margin-top: -11px;
}

.about-us-img-style {
  padding-left: 15px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .about-us-style-p {
    padding-left: 18px;
  }
  .about-us-style-p1 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .about-us-image-style1 {
    align-items: center;
    margin-bottom: 10px;
    width: 330px;
  }
  .about-us-image-style2 {
    align-items: center;
    margin-bottom: 10px;
    width: 330px;
  }
  .about-us-image-style3 {
    align-items: center;
    margin-right: 10px;
    width: 330px;
  }
}
/* -------------------why_linkCXO CSS    Start------------------------- */

.main-whyLinkcxo-style-section {
  background: #262525;
}

.why-linkcxo-video-style {
  position: absolute;
  z-index: -1.6595446;
  right: 0;
  left: 0;
  bottom: 0px;
  top: 600px;
}

.why-linkcxo-style-p {
  color: #262525;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  font-weight: 770;
  font-family: sans-serif;
  padding-bottom: 20px;
}

.intelligent-connections-style {
  color: #61c382;
  font-family: ' Raleway SemiBold';
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.get-matched-to-industry-leader-style {
  color: #fff;
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 19px;
  text-align: right;
}

.personalized-content-style {
  color: #61c382;
  font-family: 'Raleway SemiBold';
  font-size: 20px;
  line-height: 26px;
  display: flex;
  margin-bottom: 0 !important;
}

.intelligent-algorithm-that-int-style {
  color: #fff;
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 19px;
  display: flex;
}

.why-phone-image-style-section {
  height: 470px;
  width: 220px;
  display: flex;
  margin-left: 15px;
}
.why-image-style-section {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
.main-text-container-style {
  margin-top: 70px;
}
.main-text-container-style1 {
  margin-top: 70px;
}

.main-center-style-section {
  width: 100%;
  float: left;
}

@media only screen and (max-width: 768px) {
  .why-linkcxo-video-style {
    top: 1561px;
    margin-right: 10px;
    right: 10px;
  }
  .linkcxo-image-main-section {
    width: 300px;
    margin-left: 26px;
    height: 305px;
  }
  .intelligent-connections-style {
    text-align: left;
    padding-left: 15px;
    padding-top: 5px;
  }
  .get-matched-to-industry-leader-style {
    text-align: left;
    padding-left: 15px;
    padding-right: 10px;
  }
  .personalized-content-style {
    text-align: left;
    padding-left: 15px;
  }
  .intelligent-algorithm-that-int-style {
    text-align: left;
    padding-left: 15px;
    padding-right: 10px;
  }
  .main-center-style-section {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 70px;
  }
  .main-text-container-style {
    margin-top: -95px;
  }
  .why-phone-image-style-section {
    height: 400px;
    width: 200px;
  }
  .main-text-container-style1 {
    margin-top: 70px;
  }
  .main-center-style-section {
    margin-top: 70px;
  }
  .why-phone-image-style-section1 {
    margin-top: -120px;
    margin-left: -8px;
  }
  .why-image-style-section {
    width: 50px;
    margin: 10px;
  }
}
*/

/* -------------------what_our_member CSS    Start------------------------- */

.main-what-our-member-style-section {
  min-height: 150px;
  margin-top: 10px;
}
.main-what-our-member-style-section1 {
  min-height: 200px;
}

.main-what-our-style-section {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Testimonial.png')
    no-repeat center;
  background-size: cover;
}
.what-our-members-hav-style {
  color: #262525;
  font-weight: 750;
  font-family: sans-serif;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
}

.custom-Right-Arrow {
  position: absolute;
  z-index: 9999;
  right: 0px;
  top: 44px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow {
  position: absolute;
  z-index: 9999;
  left: 0px;
  top: 44px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  background: none;
  outline: none;
}
.custom-Right-Arrow:hover {
  background: #1c4586;
  color: white;
}
.custom-Left-Arrow:hover {
  background: #1c4586;
  color: white;
}

.card-image-style-section {
  width: 30%;
  float: left;
  padding-left: 30px;
}
.item-description-style-section {
  width: 70%;
  float: left;
  margin-left: -15px;
  padding-right: 20px;
}
.designation-style {
  padding-left: 0px;
  font-size: 13px;
  font-family: 'Raleway';
}
.short-text {
  color: #262525;
  font-family: 'Raleway';
  font-size: 13px;
  line-height: 16px;
  text-align: left;
}

.card-img-style-section {
  display: block;
  width: 7em;
  height: 7em;
  border-radius: 100%;
  margin: 0px;
  border: 3px solid #010408;
  background-clip: content-box;
  padding: 0em;
}
.card-img-style-section1 {
  display: block;
  height: 114px;
  width: 114px;
  border-radius: 100%;
  margin: 0px;
  border: 3px solid #010408;
  background-clip: content-box;
  background: white;
  font-size: 50px;
  font-weight: bolder;
  padding-left: 38px;
  padding-top: 15px;
}
.lorem-ipsum-dolor-style-p {
  color: #262525;
  font-family: 'Raleway medium';
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 0 !important;
  padding-right: 50px;
}
.icon-button-style-section {
  border-radius: 50%;
  background-color: #1c4586;
  color: white;
  border: none;
  font-size: 12px;
}
.all-card-name-style-p {
  padding-left: 0px;
  color: #262525;
  font-family: 'Raleway Semibold';
  line-height: 16px;
  padding-top: 10px;
}
.read-more-content {
  padding-left: 35px;
}
.react-multiple-carousel__arrow {
  width: 25px;
  height: 25px;
}
@media only screen and (max-width: 640px) {
  .card-image-style-section {
    padding-left: 5px !important;
  }
  .item-description-style-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lorem-ipsum-dolor-style-p {
    padding-left: 5px !important;
    padding-right: 0 !important;
  }
  .all-card-name-style-p {
    padding-left: 5px !important;
  }
}

/* -------------------Events-page CSS    Start------------------------- */

.main-event-style-section {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Events.png')
    no-repeat center;
  background-size: cover;
}
.events-style-section {
  color: #262525;
  font-weight: 770;
  font-family: sans-serif;
  font-size: 36px;
  line-height: 43px;
}
.events-style-section-p {
  color: #000;
  font-family: 'Raleway Medium';
  font-size: 18px;
  line-height: 24px;
}
.meet-speaker-style-section {
  width: 80%;
  float: left;
  background: white;
  border-top-left-radius: 1385px;
  border-bottom-left-radius: 1385px;
  margin-left: 50px;
  box-shadow: 0 0 50px 0 #262525;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;
}
.lorem-ipsum-dolor-style-p1 {
  color: #262525;
  font-family: 'Raleway';
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* .card-img1-style-section{height: 114px; width: 114px; border-radius: 50%; border: 2px soild black;} */
.card-image1-style-section {
  width: 20%;
  float: left;
  padding-right: 135px;
}
.item-description1-style-section {
  width: 69%;
  float: left;
  padding-left: 10px;
}
.all-card-name-style-p1 {
  margin-bottom: 0 !important;
  color: blue;
  font-size: 12px;
  font-weight: bold;
}
.meet-speaker-card-style-section {
  width: 70%;
  float: left;
  background: white;
  border-top-left-radius: 1385px;
  margin-right: 20px;
  border-bottom-left-radius: 1385px;
}
.custom-Right-Arrow2 {
  position: absolute;
  z-index: 9999;
  right: 0px;
  top: 85px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  background: none;
  outline: none;
}
.custom-Left-Arrow2 {
  position: absolute;
  z-index: 9999;
  left: 0px;
  top: 85px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  background: none;
  outline: none;
}
.custom-Right-Arrow2:hover {
  background: #1c4586;
  color: white;
}
.custom-Left-Arrow2:hover {
  background: #1c4586;
  color: white;
}
.card-img1-style-section {
  display: block;
  width: 8em;
  height: 8em;
  border-radius: 100%;
  margin: 0px;
  border: 3px solid #1c4586;
  background-clip: content-box;
  padding: 0em;
}
/* mobile-view- */
@media only screen and (max-width: 786px) {
  .meet-speaker-style-section {
    width: 95%;
    margin-left: 5px !important;
  }
  .item-description1-style-section {
    width: 65%;
    float: left;
  }
  .lorem-ipsum-dolor-style-p1 {
    padding-right: 0 !important ;
  }
  .card-img1-style-section {
    margin-top: 4px;
  }
  .all-card-name-style-p1 {
    font-size: 14px;
  }
  .what-our-members-hav-style {
    font-size: 25px;
  }
  .events-style-section {
    text-align: center;
  }
  .events-style-section-p {
    display: flex;
    font-size: 15px;
    padding-left: 20px;
  }
}

/* -------------------jobs-page CSS    Start------------------------- */

.main-carousel-style-section {
  min-height: 380px;
}
.main-jobs-style-section1 {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Jobs.png')
    no-repeat center;
  background-size: cover;
}
.jobs-style-section-heading {
  color: #fff;
  font-weight: 770;
  font-family: sans-serif;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
}
.jobs-style-section {
  transition: transform 0.2s;
  width: 250px;
  height: 275px;
  float: left;
  border-radius: 10px;
  /* background-color: #F7F7F7; */
}
.MuiSvgIcon-root-20 {
  color: green;
}
.react-multi-carousel-dot--active button :active {
  background: #4caf50;
}

.jobs-style-section:hover {
  box-shadow: 0 0 25px 0 #000;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
.jobs-style-section-description {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
/* .jobs-style-section{ height: 250px;  width: 200px; background:#F7F7F7; float: left;} */
.chief-financial-offi-style {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  line-height: 26px;
  padding-top: 33px;
  text-align: center;
}
.job-card-data-loaction-style {
  color: white;
  font-family: 'Raleway';
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 7px !important;
}
.didnt-find-what-you-style {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 18px;
  line-height: 22px;
}
.post-a-job-on-our-ap-style {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}
.join-us-button-style {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  font-family: 'Raleway SemiBold';
}
.custom-Right-Arrow1 {
  position: absolute;
  z-index: -1.34567;
  right: 20px;
  top: 190px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-decoration: none;
  border: none;
  background: none;
  outline: none;
}
.custom-Right-Arrow1:hover {
  background: #1c4586;
  color: white;
}
.custom-Left-Arrow1:hover {
  background: #1c4586;
  color: white;
}
.custom-Left-Arrow1 {
  position: absolute;
  z-index: -1.34567;
  left: 0px;
  top: 190px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  background: none;
  outline: none;
  text-decoration: none;
}
.location-style {
  width: 10px;
  height: 10px;
}
.job-card-data-loaction-style1 {
  color: #39f;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Raleway Medium';
  padding-left: 35px;
  padding-bottom: 30px;
}
/* .view-jobs-title-style-section {
  background-color: #262525;
  color: white;
} */
/* .company-name-style-section {
  color: #38cd84;
  font-family: "Raleway Medium";
  font-size: 20px;
  line-height: 23px;
}
.confidential-style-section {
  color: #262525;
  font-family: "Raleway Medium";
  font-size: 18px;
  line-height: 21px;
  padding-left: 32px;
}
.confidential-style-section1 {
  color: #262525;
  font-family: "Raleway Medium";
  font-size: 18px;
  line-height: 21px;
  padding-left: 32px;
} */
.view-jobs-title-style-section1 {
  background-color: #262525;
  color: white;
  padding-top: 8px;
}

/* mobile-view- */
@media only screen and (max-width: 640px) {
  .jobs-style-section-heading {
    font-family: 'Raleway ExtraBold';
    font-size: 30px;
  }
  .jobs-style-section-description {
    font-size: 18px;
    font-family: 'Raleway';
    text-align: left;
  }
  .jobs-style-section {
    margin-left: 70px;
    width: 210px;
    height: 250px;
  }
  .chief-financial-offi-style {
    font-family: 'Raleway bold';
    font-size: 25px;
  }
  .job-card-data-loaction-style {
    font-family: 'Raleway';
    font-size: 15px;
  }
  .didnt-find-what-you-style {
    font-family: 'Raleway';
    font-size: 18px;
  }
  .post-a-job-on-our-ap-style {
    font-family: 'Raleway';
    font-size: 18px;
    margin-top: 10px;
    text-align: left;
  }
  .join-us-button-style {
    margin-left: 110px;
  }
}

/* -------------------bussiness-page CSS    Start------------------------- */

.main-business-carousel-style-section {
  min-height: 400px;
  width: 100%;
  max-width: 1500px;
}
.main-bussiness-style-section {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/Business.png')
    no-repeat center;
  background-size: cover;
}
.bussiness-card-data-loaction-style {
  color: #000;
  font-family: 'Raleway';
  font-size: 13px;
  line-height: 16px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
}
.explore-style {
  color: #fff;
  font-family: Raleway;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  padding-right: 15px;
  margin-bottom: 0 !important;
}
.lorem-ipsum-style {
  color: #000;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  line-height: 21px;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
.bussiness-style-section {
  height: 300px;
  width: 200px;
  border-radius: 10px;
  position: relative;
  border: 2px #1c4586;
}
/* .bussiness-image-icon-style{position: absolute;z-index: 9999; right: 15px;  height: 65px; width: 65px; background-color: #262525; border-radius: 50%; top:60px;}  */
.bussiness-image-icon-style {
  position: absolute;
  z-index: 9999;
  right: 15px;
  display: block;
  width: 5em;
  height: 5em;
  border-radius: 100%;
  background-color: #02130c;
  margin: 5px;
  border: 8px solid #1c4586;
  content: '';
  background-clip: content-box;
  padding: 0em;
  top: 50px;
}
@media only screen and (max-width: 786px) {
  .bussiness-style-section {
    margin-left: 70px;
  }
  .lorem-ipsum-style {
    font-family: 'Raleway SemiBold';
    font-size: 18px;
  }
  .bussiness-card-data-loaction-style {
    font-family: 'Raleway';
  }
}

/* -------------------FAQ-page CSS    Start------------------------- */

.faq-style-section-heading {
  color: black;
  font-family: 'Raleway ExtraBold';
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  font-weight: bold;
}
.tab-faq-style-section {
  text-decoration: none;
  border: none;
}
.rc-tabs {
  border: none !important;
}
.rc-tabs-tab {
  padding: 5px;
  text-transform: capitalize;
}
.rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
}
.rc-tabs-tab.rc-tabs-tab-active {
  background: ececec;
  color: #00a6f8;
}
.rc-tabs-ink-bar {
  background: #59c7fd !important;
}
.rc-tabs-content-holder {
  padding: 10px;
  background: #ececec;
}
.react-tabs__tab--selected {
  background-color: #1c4586;
  color: white;
  border-radius: 5px 5px 0 0;
}
.react-tabs__tab--selected {
  background-color: #1c4586;
  color: white;
  border-radius: 5px;
}
.tab-upcoming-style-section-p {
  font-size: 24px;
}
.tab-upcoming-style-section-p:active {
  background-color: #1c4586;
  color: black;
}

/* .tab-upcoming-style-section-p:hover{
background-color: #1C4586; color: white; } */
/* .tab-upcoming-style-section-p:active{background-color: #1C4586; color: black }
.tab-upcoming-style-section-p:hover{ background-color: #1C4586; color: white; } */
/* .react-tabs__tab--selected {
  background: #1C4586;
  border-color: #aaa;
  color: white;
  border-radius: 5px 5px 0 0;
} */

/* .react-tabs__tab--selected {background:  #1C4586; color: white;} */
/* .tab-upcoming-style-section-p:active{
background: #2422b1; color:white} */

.tab-upcoming-style-section {
  font-size: 20px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  font-family: 'Raleway SemiBold';
}
.TAB-PAST-STYLE-SECTION {
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  border: none !important;
  list-style: none;
  cursor: pointer;
  font-family: 'Raleway SemiBold';
}
.image-container-style {
  position: relative;
  text-align: center;
  color: white;
  height: 325px;
  width: 550px;
  box-shadow: 0 0 50px 0 #262525;
}
.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 18px;
}
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  height: 115px;
  width: 510px;
  opacity: 1;
  background-color: rgba(38, 37, 37, 0.5);
}

.top-left2 {
  position: absolute;
  top: 50px;
  left: 50px;
  height: 225px;
  width: 450px;
  opacity: 1;
  background-color: rgba(38, 37, 37, 0.5);
}
.top-left-p11 {
  font-family: 'Raleway SemiBold';
  font-size: 27px;
  color: white;
  text-align: left;
  padding-left: 10px;
  padding-top: 80px;
  line-height: 28px;
}

.top-left-p {
  font-family: 'Raleway SemiBold';
  font-size: 19px;
  color: white;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
.top-left-p1 {
  font-size: 16px;
  color: white;
  text-align: right;
  padding-right: 10px;
}
.top-left-p2 {
  font-size: 17px;
  color: white;
  text-align: right;
  padding-right: 10px;
  font-family: 'sans-serif';
  font-weight: 800;
}

.upcoming-style {
  position: relative;
}
.image-style-tabs-section {
  height: 325px;
  width: 550px;
}
.main-up-coming-style-section {
  position: absolute;
  z-index: -1.4525;
  right: 0px;
  bottom: 60px;
}
.main-up-coming-style-section1 {
  position: absolute;
  z-index: -1.4525;
  right: 0px;
  bottom: 20px;
}

#text {
  display: none;
}
.next-booton-style {
  margin-top: 10px;
}
.next-booton-styles1:hover {
  background-color: blue;
}
.next-booton-styles11:hover {
  color: white;
}
.next-booton-styles1 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  background: none;
}
@media only screen and (max-width: 786px) {
  .main-up-coming-style-section {
    position: absolute;
    bottom: -436px;
    width: 300px;
  }
  .upcoming-style {
    margin-bottom: 300px;
  }
  .image-style-tabs-section {
    margin-left: 10px;
    margin-right: 10px;
  }
  .next-booton-style {
    float: left;
  }
}

/* ==========================================join-us============================================================== */
.main-join-us-text-style {
  background-color: #262525;
  color: white;
  font-family: 'Raleway';
}
.main-join-us-text-style1 {
  background-color: #262525;
  color: white;
  font-family: 'Raleway';
  height: 640px;
}
.card-style-user-section {
  width: 450px;
  border-radius: 25px;
  background-color: #f7f7f7;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 50px;
  padding-left: 5px;
}
.card-style-text-section {
  width: 375px;
  border-radius: 25px;
  background-color: #f7f7f7;
  padding-bottom: 50px;
}
.card-style-text-section1 {
  width: 375px;
  border-radius: 25px;
  background-color: #f7f7f7;
}
.main-join-us-field-style {
  background: url('https://linkcxo-test.s3.ap-south-1.amazonaws.com/LX+App+BG+(1).png')
    no-repeat center center fixed;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 660px;
  background-size: 100%;
  overflow: hidden;
}
.USER-up-with-text {
  color: #fff;
  font-family: 'Raleway SemiBold';
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.margin-top--large {
  margin-top: 4rem;
}
.margin-top--small {
  margin-top: 1rem;
  margin-bottom: -1rem;
}
.resend-otp-text-style {
  color: #252626;
  font-family: 'Raleway Medium';
  font-size: 12px;
  line-height: 14px;
}
.error {
  border: 1px solid red !important;
}
.inputStyle {
  width: 2rem !important;
  height: 2rem;
  margin: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #38cd84;
}
.btn-style-section {
  background-color: #38cd84;
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  margin: 5rem;
}
.margin-top--medium {
  margin-top: 1.9rem;
}
.btn-style-section:disabled {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}
.join-us-heading-style-section {
  text-align: center;
  font-size: 40px;
  font-family: 'Raleway ExtraBold';
}
.designationlogo-style {
  width: 25px;
  height: 25px;
  color: lightgreen;
}
.covidlogo-style {
  height: 16px;
  color: lightgreen;
}
.lorempara-style {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 20px;
  line-height: 23px;
}
.join-us-style {
  height: 41px;
  width: 137px;
  color: #fff;
  font-family: 'Raleway SemiBold';
  font-size: 35px;
  line-height: 41px;
  text-align: center;
  padding-top: 30px;
}
.sing-up-with-text1 {
  color: #38cd84;
  font-family: 'Raleway Medium';
  font-size: 24px;
  letter-spacing: 0.63px;
  line-height: 28px;
  padding-top: 50px;
  padding-bottom: 30px;
}
.already-login-style-section {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 15px;
  letter-spacing: 0.98px;
  line-height: 18px;
}
.name-style {
  color: #fff;
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 19px;
}
.form-stytle-section {
  margin-left: 40px;
  margin-right: 40px;
}
.form-stytle-section-dropdwon {
  width: 35%;
  float: left;
  background: #38cd84;
  height: 38px;
  color: 'white';
  border: none;
  float: left;
  border-radius: 4px 0px 0px 4px;
  border-right: 2px solid white;
}
.form-stytle-section-email {
  width: 85%;
  background: #38cd84;
  color: 'white';
  float: left;
  border-radius: 4px 0px 0px 4px;
  border-right: 2px solid white;
  box-sizing: border-box;
  padding: 7px 10px;
  outline: none;
  box-sizing: border-box;
  display: inline-block;
  border-bottom: #38cd84;
  border-top: #38cd84;
  border-left: #38cd84;
}
.form-stytle-section-input {
  width: 50%;
  background: #38cd84;
  color: 'white';
  float: left;
  border-radius: 0px 0px 0px 0px;
  border-right: 2px solid white;
  box-sizing: border-box;
  font-family: 'Times New Roman', Times, serif;
  box-sizing: border-box;
  padding: 7px 10px;
  outline: none;
  box-sizing: border-box;
  display: inline-block;
  border-bottom: #38cd84;
  border-top: #38cd84;
  border-left: #38cd84;
}
.form-stytle-section-input1 {
  width: 15%;
  background: #38cd84;
  color: 'white';
  border: none;
  float: right;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  padding: 6.5px 10px;
}
.form-stytle-section-input::placeholder {
  color: white;
}
.form-stytle-section-email::placeholder {
  color: white;
}
/* style={{alignItems:"center",paddingBottom:"10px" ,float:"left",paddingLeft:"100px", paddingRight:"118px"}} */
.switch-toggle-style-section {
  width: 100%;
  height: 50px;
  float: left;
}
@media only screen and (max-width: 600px) {
  .card-style-text-section {
    margin: 10px;
  }
}
.input-otp-style-section {
  width: 2em;
  text-align: center;
}
.inputbox-style {
  border: none;
  background: transparent;
  border-bottom: 1px solid white;
  outline: none;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}
.inputbox-style1 {
  border: none;
  background: transparent;
  border-bottom: 1px solid white;
  outline: none;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}
.user-select-style {
  border: none;
  background: transparent;
  border-bottom: 1px solid #c8c8c8;
  outline: none;
  color: #262525;
  font-family: 'Raleway Medium';
  width: 100%;
}
.inputbox-style1::selection {
  color: white;
}
.user-first-name-style {
  border: none;
  background: transparent;
  border-bottom: 1px solid #c8c8c8;
  outline: none;
  color: #262525;
  font-family: 'Raleway Medium';
  width: 100%;
}
.col-field1-style {
  padding-top: 20px;
}
.user-image-style {
  padding-top: 18px;
  padding-left: 25px;
}
.user-filter-style {
  padding-top: 18px;
  padding-left: 33px;
}
.user-label-style {
  padding-top: 5px;
  color: #575757;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
}
.col1-field1-style {
  padding-top: 38px;
  padding-left: 25px;
}
.add-bio-style-section {
  height: 500px;
  border-radius: 7px;
  background-color: #fff;
  margin-top: 50px;
}
.card-line-style {
  height: 1px;
  background-color: #3dcd84;
  width: 100%;
}
.add-bio-text-area-style {
  width: 100%;
  height: 375px;
  border: none;
  padding: 20px;
  outline: none;
}
.add-bio-style-text {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  letter-spacing: 0.21px;
  line-height: 18px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.btn1nside-signup-style {
  height: 28px;
  width: 96px;
  color: #fff;
  font-family: 'Raleway SemiBold';
  font-size: 24px;
  line-height: 28px;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-left: 40px;
}
.select-style {
  background: transparent;
  color: white;
  margin-right: 50px;
}
.join-us-btn-style-section {
  float: right;
  align-items: center;
  margin-left: 50px;
}
.user-button-style-section {
  border-radius: 50px;
  background-color: #38cd84;
  border: none;
  color: white;
  padding: 7px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Raleway SemiBold';
}
.user-button-style-sections {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  background-color: #38cd84;
  color: white;
  text-align: center;
}
.user-button-style-section1 {
  border-radius: 50px;
  background-color: #38cd84;
  border: none;
  color: white;
  padding: 7px 55px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Raleway SemiBold';
}
.join-text-style-section-p {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 23px;
  color: #fff;
}
.logoo-style {
  padding-left: 35px;
}
.FAQs-style {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: Raleway;
}

.faqone-style {
  font-size: 20px;
  font-family: sans-serif;
  border: 2px solid lightgray;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: bold;
  cursor: pointer;
}

.loremp-style {
  padding-top: 10px;
}
.faqtwo-style {
  font-size: 20px;
  font-family: sans-serif;
  border: 2px solid lightgray;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgb(207, 207, 207);
  font-weight: bold;
  cursor: pointer;
  display: none;
}

.accordion {
  color: white;
  cursor: pointer;
  padding: 5px;
  transition: 0.4s;
}
.pannel {
  background-color: white;
  display: none;
  overflow: hidden;
}
.tab-style {
  margin-left: 300px;
  padding-left: 200px;
}

.reference-style {
  color: black;
  font-size: 15px;
  font-weight: bold;
  font-family: Rale way;
}
.btnfield-style {
  height: 50px;
  width: 200px;
  border-radius: 3px;
  background-color: #38cd84;
}
.tabs-style {
  padding-left: 500px;
}
@media only screen and (max-width: 600px) {
  .join-text-style-p {
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
  }
  .join-us-style {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .btnplace-style {
    padding-left: 90px;
    padding-right: 90px;
  }
  .main-input-style-section {
    padding-left: 30px;
    padding-right: 20px;
  }
  .col1-field1-style {
    padding-left: 30px;
    padding-right: 30px;
  }
  .reference-style {
    padding-left: 20px;
    padding-right: 30px;
    text-align: justify;
  }
}
.logoo-style-section {
  margin-left: 100px;
}
.sing-up-with-text {
  font-family: 'Raelway';
  text-align: center;
  font-size: 25px;
  color: #38cd84;
}
.linkedin-style-section {
  height: 40px;
  width: 250px;
  margin-left: 70px;
  cursor: pointer;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #38cd84;
  padding-left: 40px;
  padding-right: 40px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #38cd84;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

/*---------------------------------------- START: Loader --------------------------------- */
#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1040;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #d01b48;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

#myOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #000;
  opacity: 0.3;
}
/* ----------------------------END: Loader ------------------------------- */

/* ----------------------Start:scroll top icon------------------- */

.topicon {
  display: none;
  position: fixed;
  bottom: 10px;
  right: -70px;
  float: right;
  opacity: 0.9;
  z-index: 99;
}
.topicon img {
  width: 50%;
  margin-left: 30px;
}
/* ----------------------End:scroll top icon------------------- */

/* =========================USer-intrested-page========================================== */
.user-checkbox-design-style {
  height: 150px;
  width: 125px;
  border-radius: 10px;
  float: left;
  margin-right: 50px;
  margin-bottom: 50px;
  position: relative;
}
.user-checkbox-design-style-input {
  height: 150px;
  width: 150px;
  border-radius: 20px;
  border-style: none;
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
}
.main-checkbox-design-style {
  height: 130px;
  width: 130px;
  border: 2px solid #38cd84;
  border-radius: 10px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.checkbox-design-style-input {
  height: 124px;
  width: 125px;
  border: 2px solid #38cd84;
  border-radius: 10px;
  float: left;
  margin-right: 20px;
  border-style: none;
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
  transition: 500ms all;
  background-color: #38cd84;
}
.checkbox-design-style-input:checked {
  background: green;
  opacity: -5.4;
  box-sizing: border-box;
  text-decoration: none;
}
/* input[type="checkbox"]:checked {background:green;      box-sizing: border-box; text-decoration: none;} */
.checkbox-design-style-image {
  margin-top: 20px;
  margin-left: 65px;
}
.user-checkbox-design-style-p {
  padding-left: 20px;
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
}
.user-checkbox-design-style-p1 {
  padding-right: 20px;
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.checkbox-design-style-p {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  text-align: center;
}
[type='radio']:checked + img {
  outline: 2px solid #f00;
}

/* ====================Privacy-polcy====================== */

.privacy-policy {
  width: 100%;
  float: left;
  margin-top: 120px;
  color: #fff;
  padding: 0px 50px 50px 90px;
}
.privacy-policy p {
  color: #fff;
}
.privacy-policy ul {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.privacy-policy ul li {
  width: 100%;
  float: left;
  list-style-type: disc;
  margin-left: 40px;
  padding: 5px 0;
}
.privacy-policy h2 {
  text-align: center;
  color: #38cd84;
}
.privacy-policy h3 {
  color: #38cd84;
}
.privacy-policy h4 {
  color: #38cd84;
}
.privacy-policy-heading {
  /* background: #aeaeae; */
  width: 100%;
  float: left;
}

.wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

/* ============================================================================================= */
.card-prev,
.cardnext {
  cursor: pointer;
  width: auto;
  color: #1f79b5;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  display: inline-block;
  margin-top: 2%;
}
.card-prev {
  position: absolute;
  top: 45%;
  left: 10%;
}
.card-next {
  position: absolute;
  top: 50%;
  right: 11%;
}
#back {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 90%;
  left: 42%;
}
.service-dot-container {
  text-align: center;
  padding: 9px;
  position: absolute;
  /* top:91%; */
  bottom: 50px;
  right: 47%;
}

.service-dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 10px 2px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #2f4672;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.service-dot:hover,
.active-services {
  background: #2f4672;
}
.card-slide {
  display: none;
  transition: display 4s;
  /* margin: 0 10% 0 20%; */
  padding: 3% 2%;
  width: 85%;
  float: right;
}
#card-slide1 {
  display: inline-block;
}
.cardSubService {
  text-align: center;
  /* margin: 0 10% 0 20%; */
  background: #7cbae9;
  border-radius: 10px;
  width: 100%;
  opacity: 0.8;
  height: 500px;
  padding: 10px 100px;
  position: relative;
}

/* =========================tabs-page===================================================================== */
#tab-Control-ClassName {
  border: none;
  text-decoration-style: none;
  margin-left: 100px;
  margin-right: 50px;
  background: none;
  outline: none;
  font: inherit;
  font-family: 'Raleway SemiBold';
  font-size: 22px;
}
#tab-Control-ClassName1 {
  border: none;
  text-decoration-style: none;
  margin-left: 50px;
  margin-right: 50px;
  background: none;
  outline: none;
  font: inherit;
  font-family: 'Raleway SemiBold';
  font-size: 22px;
}
#tab-Control-ClassName2 {
  border: none;
  text-decoration-style: none;
  margin-left: 50px;
  margin-right: 50px;
  background: none;
  outline: none;
  font: inherit;
  font-family: 'Raleway SemiBold';
  font-size: 22px;
}

.card-header {
  position: relative;
  border: 1px solid #1f1e1e;
}

.click-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  height: 60px;
  width: 1110px;
  border-radius: 3px;
}

.isRotated svg {
  transform: rotate(90deg);
}

.card-header-sub {
  font-family: 'Raleway SemiBold';
}

.card-body-sub {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 104px;
  width: 900px;
  color: #4f4f4f;
  font-family: 'Raleway SemiBold';
  font-size: 22px;
  line-height: 26px;
}

.collapsible {
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: 1px solid #1c4586;
  text-align: left;
  outline: none;
  font-size: 15px;
  margin-bottom: 10px;
  background: none;
  font: inherit;
}

.active,
.collapsible:hover {
  color: #fff;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
  color: black;
}

.collapsible:after {
  content: '\02F2'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
}

.active:after {
  /* content: "\2796"; Unicode character for "minus" sign (- ) */
}

/* ===========================linkcxo-Desktop-version-css==================================================== */

.nave-bar-menu-desktop-style {
  color: white;
  font-size: 8.5px;
  font-family: 'Raleway SemiBold';
}
.nave-bar-menu-desktop-style-hover {
  color: #38cd84;
}

.search-style-section {
  width: 350px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.1);
}

.search-style-section::placeholder {
  color: white;
}
.nav-style-desktop-menu {
  padding: 0px !important;
}
.react-switch-bg {
  background: #38cd84;
}
/* ==========================Profile-section========================================== */
.profile-card-image-style {
  margin-top: 10px;
}
.profile-icon-style-section {
  height: 57px;
  width: 57px;
  border-radius: 50%;
  background: #808080;
  margin: auto;
  padding-top: 20px;
  padding-left: 10px;
}
.profile-icon-style-section1 {
  height: 67px;
  width: 67px;
  border-radius: 50%;
  background: #808080;
  margin: auto;
  padding-top: 22px;
  padding-left: 19px;
  margin-top: 5px;
  cursor: pointer;
}
.profile-logo-style-section {
  color: white;
  font-family: 'Raleway bold';
  font-size: 40px;
  letter-spacing: 0.22px;
  line-height: 16px;
  font-weight: bold;
}
.profile-style-section {
  height: 150px;
}
.view-profile-card-stylr-section {
  border-radius: 7px;
  background-color: #fff;
  padding-bottom: 20px;
  width: 100%;
  cursor: pointer;
}
.view-profile-image-style {
  border-radius: 50%;
  align-items: center;
  align-content: center;
  margin-top: 7px;
}
.view-profile-card-name-style {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
}
.view-profile-card-designation-style {
  color: #959498;
  font-family: 'Raleway SemiBold';
  font-size: 10px;
  letter-spacing: 0.16px;
  line-height: 12px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.split {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  border: 0px solid black;
  padding-left: 3px;
  padding-right: 3px;
}
.count {
  font-family: open sans-serif;
}
/* ==========================Network-People-section========================================== */

.network-people-style-section {
  position: fixed;
  border-radius: 7px;
  background-color: #fff;
  padding: 10px;
  width: 21%;
}

.network-people-heading {
  color: #3dcd84;
  font-family: 'Raleway SemiBold';
  font-size: 10px;
  letter-spacing: 0.16px;
  line-height: 12px;
  padding-left: 10px;
}

.network-people-seeall {
  font-family: 'Raleway SemiBold';
  font-size: 12px;
  letter-spacing: 0.13px;
  line-height: 9px;
  cursor: pointer;
}

.network-cards-style-section {
  height: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0px;
}

.network-people-name-style {
  color: #3dcd84;
  font-family: 'Raleway Medium';
  font-size: 10px;
  letter-spacing: 0.16px;
  line-height: 12px;
}

.network-people-designation-style {
  color: #959498;
  font-family: 'Raleway Medium';
  font-size: 8px;
  letter-spacing: 0.13px;
  line-height: 9px;
}

.network-people-main-style-section {
  padding-left: 36px;
  cursor: pointer;
}

.network-people-button-style-section {
  border-radius: 15px;
  background-color: #3dcd84;
  border: none;
  color: white;
  padding: 0px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
}
.network-people-button-style-section1 {
  border-radius: 15px;
  background-color: #959498;
  border: none;
  color: white;
  padding: 0px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
}

/* =============================share-your-experience======================================================== */
.main-post-list-style-section {
  margin-right: 0px;
}
.shere-your-experience-style-section {
  height: 60px;
  border-radius: 7px;
  background-color: #fff;
  padding-right: 10px;
}
.share-experience-text-style-section {
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-top: 15px;
}

.share-text-style {
  color: #959498;
  font-family: 'Raleway Medium';
  font-size: 15px;
  letter-spacing: 0.23px;
  line-height: 18px;
  cursor: pointer;
}

/* =============================View-post-list======================================================== */

.view-post-list-style-section {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
  border-radius: 7px;
}
.post-list-card-style {
  padding-left: 0px;
  padding-right: 0px;
}
.post-list-card-style-section {
  border-radius: 7px;
  background-color: #fff;
  margin-bottom: 5px;
}
.wrapper-post-view-heading-style {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
}
.row-1-card-img-name-style-section {
  width: 100%;
  float: left;
}
.card-body {
  padding: 0.7rem;
}
.view-post-name-style-section {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
}
.view-post-designation-style-section {
  color: #959498;
  font-family: 'Raleway Medium';
  font-size: 14px;
  line-height: 16px;
}
.view-post-time-style-section {
  color: #b9b7be;
  font-family: 'Open Sans Semibold';
  font-size: 11px;
  line-height: 15px;
  padding-left: 10px;
}

.view-post-applauses-style {
  color: #959498;
  font-family: 'Raleway SemiBold';
  font-size: 11px;
  line-height: 13px;
}
.view-post-comment-style {
  color: #959498;
  font-family: 'Raleway SemiBold';
  font-size: 11px;
  line-height: 13px;
  cursor: pointer;
}
.view-post-share-style {
  color: #959498;
  font-family: 'Raleway SemiBold';
  font-size: 11px;
  line-height: 13px;
}

/* ========more-section====================== */
.main-more-style-section1 {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  right: 4%;
  cursor: pointer;
  padding: 10px;
  top: 200px;
  font-size: 13px;
  width: 30%;
  background: #fff;
  text-align: left;
  border-radius: 7px;
}
.main-more-style-section {
  position: absolute;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  right: 4%;
  cursor: pointer;
  padding: 10px;
  top: 17%;
  z-index: 9999;
  font-size: 13px;
  width: 30%;
  background: #fff;
  text-align: left;
  border-radius: 7px;
}
.main-more-text-style-section {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 14px;
  letter-spacing: 0.79px;
  line-height: 25px;
}
/* =========================================Create-post========================================================== */

.create-post-heading-style {
  width: 100%;
  float: left;
  padding-top: 15px;
  border-bottom: 1px solid #3dcd84;
  padding-bottom: 15px;
}
.create-post-heading-style-left {
  width: 97%;
  float: left;
}
.create-post-heading-style-right {
  width: 3%;
  float: right;
}
.share-post-heading-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
}
.share-close-button-style {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.cross-close-button-style {
  float: right;
  cursor: pointer;
  padding-left: 60px;
}
.share-close-button-style:hover {
  color: red;
  background: lightgray;
  border-radius: 50%;
}

.create-editer-style-section {
  width: 100%;
  float: left;
  height: 330px;
  border-bottom: 1px solid #3dcd84;
}
.create-editer-style-section1 {
  width: 100%;
  float: left;
  border-bottom: 1px solid lightgrey;
}

.create-heading-style-section {
  width: 100%;
  float: left;
}
/* .rdw-editor-toolbar { border-bottom: 2px solid #3DCD84;} */

.MuiDialogContent-root-78 {
  padding: 0px !important;
}
.rdw-editor-main {
  padding-left: 20px;
}
.create-post-file-style-section {
  width: 100%;
  float: left;
  height: 50px;
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
}

.create-image-post-style-section {
  width: 20%;
  float: left;
  padding-left: 20px;
}

.create-document-post-style-section {
  width: 20%;
  float: left;
}
.create-document-post-style-section1 {
  width: 100%;
  float: left;
}

.create-video-post-style-section {
  width: 20%;
  float: right;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  border: none;
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 14px;
  line-height: 16px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: -19px;
}

.create-post-button-style-section {
  width: 100%;
  float: left;
  height: 50px;
  margin-bottom: 10px;
}

.create-post-button-left-style-section {
  width: 60%;
  float: left;
}
.hash_tag-style-form-control {
  border: none;
  outline: none;
}
.create-post-button-right-style-section {
  width: 40%;
  float: right;
  padding-top: 10px;
  padding-bottom: 10px;
}

.preview-btn-style-section {
  background-color: #3dcd84;
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 25px;
}
.post-btn-style-section {
  background-color: #3dcd84;
  border: none;
  color: white;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 25px;
  float: right;
}
.image-input-style-section {
  position: absolute;
  left: 60px;
  opacity: 0.1;
}
.articals-file-upload-style {
  position: absolute;
  left: 20px;
  opacity: 0.1;
  top: 431px;
}
/* ----------------comment-section------------------------------ */
.main-comment-text-box-style-section {
  width: 100%;
  padding: 8px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
  margin-left: 10px;
  outline: none;
}
.comment-btn-style-section {
  border: none;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: #38cd84;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  color: white;
}
.main-comment-view-style-section {
  border-radius: 0px 5px 5px 5px;
  color: black;
  margin-top: 15px;
  background: #ececec;
  padding: 16px 16px 12px 12px;
  transition: background-color 1s ease;
}
.main-comment-name-view-style-section {
  font-size: 14px;
  font-family: 'Raleway SemiBold';
}

.view-comment-content-style-section {
  font-size: 13px;
  font-family: 'Raleway medium';
  color: black;
}
.select-style-form-control {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
}
/* =====================================================Network-Section========================================================================================= */

/* .main-network-container-style-section{ height: 60px; border-radius: 7px; background-color: #FFF;text-decoration: none; border:none; float: left; width: 60%; z-index: 99;} */
.main-network-container-style-section1 {
  border-radius: 7px;
  background-color: #fff;
  text-decoration: none;
  border: none;
  float: left;
  width: 100%;
  height: 60px;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 7px;
}
.no-padding-style {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.main-img-square-wrapper {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  cursor: pointer;
}
.my-network-card-style-section {
  border-radius: 7px;
  background-color: #fff;
}
.connect-style-section {
  margin-right: 50px;
  margin-left: 50px;
}
.ignore-style-section {
  margin-left: 100px;
}
.card-footer-style-section {
  background: #fff;
}

.card-title-style-section {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  letter-spacing: 0.23px;
  line-height: 18px;
}

.card-sub-title-style-section {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
}

.main-connect-style-section {
  color: #3dcd84;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  cursor: pointer;
}

.main-ignore-style-section {
  color: #959498;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  cursor: pointer;
}

.tab-existing-style-section {
  text-align: center;
  list-style: none;
  cursor: pointer;
  float: left;
  margin-right: 90px;
  margin-top: 13px;
  margin-left: 20px;
  outline: none;
}

.tab-existing-style-section-p {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
}
.tab-existing-style-section-btn:active {
  background-color: #38cd84;
  color: white;
}

.tab-existing-style-section-btn {
  border: none;
  padding: 5px 25px;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: none;
  outline: none;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background-color: #38cd84;
  color: white;
  border-radius: 20px;
}
.alert-btn-style-section {
  border: none;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: #38cd84;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  color: white;
}
.alert-btn-cancel-style-section {
  border: 1px solid #38cd84;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  color: #38cd84;
}

.massage-text-style-section {
  height: 20px;
  color: #fff;
  padding: 8px 15px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  margin-right: 8px;
  cursor: pointer;
}

.ignore-text-style-section {
  height: 20px;
  color: #fff;
  padding: 8px 17px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  margin-right: 6px;
  cursor: pointer;
}

.remove-text-style-section {
  height: 20px;
  color: #fff;
  padding-left: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  cursor: pointer;
}

.discover-section-style {
  border-radius: 7px;
  background-color: #fff;
  padding: 15px 30px;
}
.discover-section-style1 {
  border-radius: 7px;
  background-color: #fff;
  padding: 15px 30px;
}
.discover-text-section-style {
  color: #3dcd84;
  font-family: 'Raleway SemiBold';
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
}
.see-all-text-section-style {
  text-align: center;
  cursor: pointer;
  color: #262525;
  font-family: Raleway;
  font-size: 12px;
  line-height: 14px;
}
/* empty -message-style */
/* style={{width:"100%", float:"left", textAlign:"center", color:"#727272", marginTop:"2%", fontSize:"15px", marginBottom:"2%"}} */
.empty-message-text-style {
  width: 100%;
  float: left;
  text-align: center;
  color: #38cd84;
  margin-top: 0.5%;
  font-size: 15px;
  margin-bottom: 1.5%;
  font-family: 'Raleway';
}

/* ================================================View-Profile-section======================================================================================= */

.profile-left-basic-information-right-icon {
  width: 100%;
  float: left;
  position: absolute;
  z-index: 9;
  left: 534px;
  top: 113px;
  box-shadow: #000;
}
.view-user-profile-container-style {
  background: #fff;
  position: relative;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.profile-background-style-section {
  height: 133px;
  border-radius: 7px 7px 0 0;
  background-color: #38cd84;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.view-profile-image-style-section {
  border-radius: 50%;
  border: 2px solid #38cd84;
  position: relative;
  top: -100px;
  left: 350px;
}
.view-profile-name-style-section {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 25px;
  line-height: 29px;
}
.view-profile-designation-style-section {
  color: #959498;
  font-family: 'Raleway Medium';
  font-size: 15px;
  letter-spacing: 0.23px;
  line-height: 18px;
}
.view-profile-connection-style {
  color: #262525;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
}
.view-profile-connection-view-style {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
}
.view-profile-follwer-style {
  color: #262525;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: right;
  margin-bottom: 0px;
}
.view-profile-follower-view-style {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
  text-align: right;
}
.view-profile-description-style {
  color: #262525;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
  padding-left: 20px;
  margin-right: 20px;
}
.view-business-description-style {
  color: #3dcd84;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding-left: 100px;
  text-align: center;
}
.view-profile-category-style-section {
  color: #262525;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
.main-view-profile-style-section {
  padding-top: 80px;
}
.user-experience-style-section {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  line-height: 21px;
}
.main-user-experience-style-section {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  line-height: 21px;
  float: left;
  padding-left: 20px;
}
.wrraper-img-style-section {
  border-radius: 50%;
  margin-left: -10px;
  margin-bottom: 10px;
}
.warpper-university-name-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 17px;
  line-height: 18px;
  margin-bottom: 0px;
}
.warpper-degree-year-style {
  color: #262525;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
}
.warpper-description-style {
  color: #262525;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
}
.main-warpper-for-all-style {
  margin-top: 20px;
  border-bottom: 1px solid grey;
  padding-bottom: 20px;
}
.main-warpper-for-all-style1 {
  margin-top: 20px;
  padding-bottom: 20px;
}
.wrapper-intrest-style {
  border-radius: 20px;
  background-color: #38cd84;
  padding: 7px 30px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.wrapper-intrest-text-style {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
}
.english-text-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
}
.wrapper-english-text-style {
  color: #262525;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
}
.imgPreview {
  text-align: center;
  height: 150px;
  width: 150px;
  position: absolute;
  top: -49%;
  left: 235%;
}
.imgPreview img {
  border-radius: 50%;
}
.wrapper-company-text-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 17px;
  line-height: 18px;
}
.wrapper-company-name-text-style {
  color: #38cd84;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 18px;
}
.edit-profile-image-style-section {
  border-radius: 50%;
  border: 2px solid #38cd84;
}

/* ========Experience============== */

.warapper-text-experience-style {
  width: 100%;
}

/* =========Skills=========================== */
.skills-wrapper-style-section {
  border-bottom: 1px solid grey;
}

.react-tag-input__tag {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  line-height: 1;
  color: white;
  background: #38cd84;
  border-radius: 12px;
}

.react-tag-input__tag__remove {
  position: relative;
  height: 2em;
  width: 2em;
  font-size: 0.85em;
  cursor: pointer;
  background: #38cd84;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.react-tag-input {
  background: #38cd84;
  color: #fff;
  border: 1px solid #e1e1e1;
}
.react-tag-input__input {
  background: transparent;
  color: #333;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: #333; /* Input placeholder */
}
.react-tag-input__tag {
  background: #e1e1e1;
}
.react-tag-input__tag__remove {
  background: #d4d4d4;
}
.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: #333; /* X icon in remove button */
}
/* =========================Massege=================================================================================== */
/* .chat-body-default{
  width: 100%;
  float: left;
  height: 415px;
  overflow-y: scroll;
  background: #ffffff;
  padding: 10px;
}

.chat-body-scroll{
  height: 415px;
  background: #ffffff;
  overflow:auto; display:flex; flex-direction:column-reverse;
  padding: 10px;
  padding-bottom: 100px;
}
.all-chat-data{
  width: 100%;
  float: left;
  position: relative;
  padding: 10px;

}
.chat-header{
 min-height:150px;
} */

/* =======================================jobs-section=================================== */
.main-jobs-style-section {
  display: flex;
  flex: 1 1 auto;
  background: #fff;
  border-radius: 7px;
}
.wrapper-jobs-style-section {
  width: 100%;
  float: left;
  padding: 12px;
}
.wrapper-jobs-style-section1 {
  width: 100%;
  float: left;
  padding-left: 12px;
  padding-bottom: 12px;
}
.card-horizontal-left-section {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  float: left;
}
.card-horizontal-right-section {
  width: 100%;
  float: right;
}
.card-job-heading-style {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  letter-spacing: 0.23px;
  line-height: 18px;
}
.job-heading-wrapper {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
  float: left;
}
.card-job-sub-heading-style {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
}
.wrapper-job-button-style-section {
  background-color: #38cd84;
  border: none;
  color: white;
  padding: 5px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 20px;
  font-family: 'Raleway Medium';
  font-size: 12px;
}

.wrapper-apply-style-section {
  color: #fff;
  padding: 4px 20px;
  width: 100px;
  font-family: 'Raleway Medium';
  border: none;
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  cursor: pointer;
}

.wrapper-save-style-section {
  color: #fff;
  padding: 4px 26px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  cursor: pointer;
}

.wrapper-share-style-section {
  color: #fff;
  padding: 5px 22px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  cursor: pointer;
}

.recommended-text-section-style {
  color: #38cd84;
  font-family: 'Raleway SemiBold';
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
}
.wrapper-unsave-style-section {
  color: #fff;
  padding: 5px 20px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  cursor: pointer;
}

.wrapper-inactive-style-section {
  color: #fff;
  padding: 5px 15px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  cursor: pointer;
  margin-bottom: 20px;
}

.wrapper-edit-style-section {
  color: #fff;
  padding: 5px 24px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  cursor: pointer;
  margin-bottom: 20px;
}

.wrapper-activate-style-section {
  color: #fff;
  padding: 5px 16px;
  width: 100px;
  font-family: 'Raleway Medium';
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  cursor: pointer;
  margin-bottom: 20px;
}
.main-header-container-style {
  border-radius: 7px;
  background-color: #fff;
}
.main-header-view-jobs-style {
  width: 100%;
  float: left;
  background: #3dcd84;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 20px;
  position: sticky;
  cursor: pointer;
}
.main-header-view-jobs-style-left {
  width: 90%;
  float: left;
}
.main-header-view-jobs-style-right {
  width: 10%;
  float: right;
  cursor: pointer;
}
.main-title-style-section {
  color: #fff;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  line-height: 21px;
}
.main-sub-title-style-section {
  color: #fff;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
}
.main-location-style-section {
  color: #3dcd84;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
}
.main-location1-style-section {
  color: #262525;
  font-family: 'open sans';
  font-size: 15px;
  line-height: 18px;
  padding-left: 20px;
}
.posted-profile-style-section {
  box-sizing: border-box;
  height: 85px;
  width: 205px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: 1px solid #3dcd84;
}
.profile-view-style-section {
  border-radius: 50%;
  margin: 10px;
}
.posted-by-text-style {
  color: #262525;
  font-family: 'Raleway Medium';
  font-size: 12px;
  line-height: 14px;
}
.posted-by-name-text-style {
  color: #262525;
  font-family: 'open sans';
  font-size: 12px;
  line-height: 14px;
}
.posted-by-designation-text-style {
  color: #262525;
  font-family: 'open sans';
  font-size: 10px;
  line-height: 12px;
}

.posted-btn-style-section {
  background-color: #3dcd84;
  border-radius: 20px;
  color: #fff;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
  border: none;
  color: white;
  padding: 5px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.hold-text-style-section {
  color: #fff;
  padding: 8px 8px;
  font-family: 'Raleway Medium';
  font-size: 10px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  margin-right: 10px;
  cursor: pointer;
}

.shortlist-text-style-section {
  color: #fff;
  padding: 8px 8px;
  font-family: 'Raleway Medium';
  font-size: 10px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #53bb80;
  margin-right: 10px;
  cursor: pointer;
}

.reject-text-style-section {
  color: #fff;
  padding: 8px 9px;
  font-family: 'Raleway Medium';
  font-size: 10px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  margin-right: 10px;
  cursor: pointer;
}

.undo-text-style-section {
  color: #fff;
  padding: 8px 30px;
  font-family: 'Raleway Medium';
  font-size: 10px;
  letter-spacing: 0.19px;
  line-height: 14px;
  border-radius: 20px;
  background-color: #959498;
  margin-right: 10px;
  cursor: pointer;
}
.display-massege-text-style {
  width: 100%;
  float: left;
  text-align: center;
  font-family: open sans-serif;
  font-size: 15px;
  color: #53bb80;
  margin-bottom: 1%;
  margin-top: 1%;
}

/* Apply-jobs */
.create-jobs-apply-heading-style {
  width: 100%;
  float: left;
  padding-top: 15px;
}

.apply-jobs-image-profile-style {
  border-radius: 50%;
}
.apply-jobs-name-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
  padding-left: 20px;
}
.apply-job-message-text-style {
  color: #262525;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding-left: 17px;
}

.create-editer-for-jobs-style-section {
  width: 100%;
  float: left;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid #3dcd84;
}

.apply-jobs-file-style {
}

/* -----------create-jobs------------------- */

.main-create-jobs-style-section {
  border-radius: 7px 7px 0px 0px;
  background-color: #fff;
  padding-bottom: 50px;
}

.wrapper-create-heading-style {
  color: #262525;
  font-family: 'Raleway SemiBold';
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

/* ================message to recruiter============================ */
.main-message-container-style {
  background: #fff;
  border-radius: 7px;
  padding: 10px;
}

.heading-mesage-style {
  color: #3dcd84;
  font-family: 'Raleway Medium';
  font-size: 15px;
  line-height: 18px;
}

.message-text-style-section {
  width: 100%;
  padding: 20px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-sizing: border-box;
}

.message-btn-section {
  text-align: right;
  float: right;
}
.send-btn-message-style-section {
  background-color: #53bb80;
  border: none;
  color: white;
  padding: 5px 25px;
  font-family: 'Raleway SemiBold';
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 20px;
  font-size: 15px;
  line-height: 18px;
}

.view-message-list-style {
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.view-message-name-list-style {
  color: #3dcd84;
  font-family: 'Raleway medium';
  font-size: 14px;
  line-height: 16px;
}
.view-message-message-list-style {
  color: #262525;
  font-family: 'Raleway medium';
  font-size: 14px;
  line-height: 16px;
}

/* news-section */

.individsaull-news-card-heading-style {
  color: #3dcd84;
  font-family: 'Raleway SemiBold';
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 21px;
}
.individsual-news-card-description {
  color: #1d1d1f;
  font-family: 'Raleway Medium';
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 16px;
}

.MuiDialogContent-root-82 {
  padding: 0px !important;
}
.MuiDialogContent-root-148 {
  padding: 0px !important;
  overflow: hidden;
}

.main-dialog-Body-create-post-style {
  padding: 0 !important;
  overflow: hidden;
}

.MuiDialog-paperScrollPaper-11 {
  max-height: calc(0px);
}

.MuiDialogContent-root-88 {
  padding: 0 !important;
  overflow: hidden;
}

/* ----------left-side-signup-------------------------- */

.left-side-sign-style-seection {
  padding-top: 40px;
  padding-left: 20px;
}
.left-side-text-sign-style-seection {
  color: #fff;
  font-weight: 770;
  font-family: sans-serif;
  font-size: 20px;
  line-height: 43px;
  display: flex;
  padding-left: 40px;
  padding-right: 60px;
}

/* input[type="file"] {
  display: none;
} */

.custom-file-input {
  color: transparent;
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
  top: -34px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* ====================Business-style-section====================================== */

.container-image-style {
  position: relative;
  font-family: 'Raleway';
}

.main-business-text-block {
  position: absolute;
  color: white;
  top: 40%;
  left: 35%;
}

.main-business-image-style {
  border-radius: 7px;
}
/* covid section style */
.covid-section {
  height: auto;
  width: 100%;
  background-color: #262525;
  color: white;
  padding: 10px;
}
.covid-section-header {
  color: #38cd84;
  font-weight: bold;
  font-size: 24px;
}
.covid-button {
  background-color: #38cd84 !important;
  padding: 5px 30px;
  margin-top: 10px;
  color: white;
  font-size: 24px;
}
.covid-form-section {
  background-color: #262525;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding-top: 20px;
  color: white;
}
.personal-engage {
  color: #38cd84 !important;
  font-size: 24px;
  margin-top: 30px;
}
.input-label {
  color: #38cd84 !important;
}
.inputField {
  border-bottom: 1px solid white;
}
.react-calendar__tile--now {
  background-color: transparent !important;
}
.react-calendar__tile--active {
  border-radius: 100%;
  height: 80px !important;
  width: 65px;
  max-width: 80px !important;
  background: #38cd84 !important;
}
.react-calendar__month-view {
  height: 75px;
}

.react-calendar__month-view__days {
  height: 350px !important;
}
.react-calendar__month-view__days__day {
  /* color: teal; */
  font-family: 'Roboto';
}
.react-calendar {
  min-height: 460px;
}
.selected-slot-btn {
  background-color: #38cd84;
  color: white;
  height: 60px;
  font-family: 'Roboto';
  font-size: 14px;
  padding: 10px 0px;
}
.slot-btn {
  background-color: white;
  color: black;
  height: 60px;
  font-family: 'Roboto';
  font-size: 14px;
  padding: 10px 0px;
}
.call-button {
  background-color: white;
  border: unset;
  height: 60px;
  border-bottom: 1px solid gray !important;
}
.tick-icon {
  color: #38cd84;
}
.react-calendar__navigation__label {
  color: #38cd84 !important ;
  font-weight: bold;
}
.primary_color {
  color: #38cd84;
}
.btn-schedule {
  background-color: #38cd84;
  color: white;
}

.optionContainer li {
  color: black !important;
}
.optionContainer li:hover {
  background-color: #38cd84 !important;
  color: #ffffff !important;
}
.search-wrapper {
  border: transparent !important;
  border-bottom: 1px solid white !important;
  border-radius: 0px;
}
._7ahQImyV4dj0EpcNOjnwA {
  background-color: #38cd84 !important;
}
/* .lhyQmCtWOINviMz0WG_gr{
  background-color:#38CD84 !important;
  color: #ffffff !important;
} */

@media (max-width: 500px) {
  .job-apply {
    overflow-y: scroll;
    height: 70vh;
  }
}

.job-apply {
  height: auto;
}

.e-calendar {
  width: 100%;
  max-width: unset !important;
}
.e-title {
  color: #38cd84 !important;
  font-size: 24px !important;
}
.e-selected .e-day {
  background-color: #38cd84 !important;
}

.relocation:checked:before {
  background: green !important;
  border: unset !important;
}
.next-button {
  background-color: #38cd84;
  color: #ffffff;
  margin-bottom: 20px;
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.inputbox-style::placeholder {
  color: white !important;
}
.searchBox::placeholder {
  color: white;
}
.custom-add-industry div div .searchBox::placeholder {
  color: black !important;
}

.input-counter-counter span {
  top: 0;
  width: 30px;
  height: 100%;
  font-size: 15px;
  cursor: pointer;
  line-height: 50px;
  position: absolute;
  color: var(--paragraphColor);
  background-color: #38CD84;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.input-counter-counter span.minus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  border-right: 1px solid #38CD84;
}
.input-counter-counter span.plus-btn {
  right: 0;
  border-left: 1px solid #38CD84;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-counter-counter span:hover {
  color: var(--primaryColor);
}
.input-counter-counter input {
  outline: 0;
  width: 100%;
  height: 27px;
  display: block;
  text-align: center;
  color: var(--blackColor);
  border: 1px solid #38CD84;
  background-color: var(--whiteColor);
  font-size: 17px;
  font-weight: 600;
}
.input-counter-counter input::placeholder {
  color: var(--blackColor);
}

.input-counter-counter{
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  position: relative;
}

.input-counter-counter span {
  top: 0;
  width: 30px;
  height: 100%;
  font-size: 15px;
  cursor: pointer;
  line-height: 50px;
  position: absolute;
  color: var(--paragraphColor);
  background-color: #38CD84;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
